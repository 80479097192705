import React from "react";
import './SocialFlow.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTwitter,
    faLinkedin,
    faInstagram,
    faYoutube,
    faSnapchat,
    faWhatsapp
} from "@fortawesome/free-brands-svg-icons";

export default function SocialFlow() {
    return (
        <div>
            <div className="container">
                <div className="row justify-content-center">
                    <p className="social-container">
                        <a href="https://www.linkedin.com/in/rasana-sa-com/" className="linkedin social">
                            <FontAwesomeIcon icon={faLinkedin} size="2x" />
                        </a>
                        <a href="https://twitter.com/rasana_sa_com" className="twitter social">
                            <FontAwesomeIcon icon={faTwitter} size="2x" />
                        </a>
                        <a href="http://www.instagram.com/rasana_sa_com" className="instagram social">
                            <FontAwesomeIcon icon={faInstagram} size="2x" />
                        </a>
                        <a href="http://www.snapchat.com/rasana_sa_com" className="snapchat social">
                            <FontAwesomeIcon icon={faSnapchat} size="2x" />
                        </a>
                        <a href="https://wa.me/966550077556" className="whatsapp social">
                            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                        </a>
                        <a href="https://youtube.com/@rasana_sa_com" className="youtube social">
                            <FontAwesomeIcon icon={faYoutube} size="2x" />
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}
