import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"

let app = null;
if (!firebase.apps.length) {
  app = firebase.initializeApp({
    apiKey: "AIzaSyAq_fxXobiz7JaS7HmAN5Wix15EigTGHLs",
    authDomain: "rasana-de8f2.firebaseapp.com",
    projectId: "rasana-de8f2",
    storageBucket: "rasana-de8f2.appspot.com",
    messagingSenderId: "766880882949",
    appId: "1:766880882949:web:bf7555505235b102dd976c",
    measurementId: "G-H95J3GG2F0"
})
}

const firestore = app.firestore()

export const db = app.firestore()

export const database = {


  projectsstatus: firestore.collection("projectsstatus"),
  projectstypes: firestore.collection("projectstypes"),
  contractorstypes: firestore.collection("contractorstypes"),
  userroles: firestore.collection("userroles"),
  informations: firestore.collection("informations"),

  customers:firestore.collection("customers"),
  vendors:firestore.collection("vendors"),

  projects: firestore.collection("projects"),


  users:firestore.collection("users"),

  sales:firestore.collection("sales"),
  purchases:firestore.collection("purchases"),
  products:firestore.collection("products"),
  inventory:firestore.collection("inventory"),

  receipts:firestore.collection("receipts"),
  payments:firestore.collection("payments"),
  paymenttypes:firestore.collection("paymenttypes"),

  formatDoc: doc => {
    return { id: doc.id, ...doc.data() }
  },

  getCurrentTimestamp: firebase.firestore.FieldValue.serverTimestamp,
  
}

export const storage = app.storage()
export const auth = app.auth()
export default app
