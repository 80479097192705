import { useState, useRef, useEffect, useLayoutEffect } from "react"
import TableForm from "./TableForm"
import ReactToPrint from "react-to-print"
import QRCode from 'qrcode.react';
import logo from '../../assets/images/logo.jpg';
import { db } from '../../firebase';
import { useParams } from "react-router-dom";
import './NewSales.css'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom";
import dayjs from 'dayjs'

import { addDoc, updateDoc, collection, serverTimestamp, getDocs, getDoc, doc, query, orderBy, limitToLast, increment } from 'firebase/firestore'
const iniRec = { invoiceNumber: "", invoiceIssueDate: new Date().toISOString().slice(0, 10), dateOfSupply: new Date().toISOString().slice(0, 10), sellerName: "", buyerName: "", buyerContact: "", sellerContact: "", sellerBuildingNumber: "", buyerBuildingNumber: "", sellerStreetName: "", buyerStreetName: "", sellerDistrict: "", buyerDistrict: "", sellerCity: "", buyerCity: "", sellerCountry: "", buyerCountry: "", sellerPostalCode: "", buyerPostalCode: "", sellerAdditionalNo: "", buyerAdditionalNo: "", sellerVATNumber: "", buyerVATNumber: "", otherSellerID: "", otherBuyerID: "", balance: 0 }

function NewSales() {
  const history = useHistory();
  const { id } = useParams()
  const [newRec, setNewRec] = useState(() => iniRec);
  const [url, setUrl] = useState("");
  const [showInvoice, setShowInvoice] = useState(false)
  const [balanceMore, setBalanceMore] = useState(false)
  const [totalAmounts, setTotalAmounts] = useState("")
  const [totalDiscounts, setTotalDiscounts] = useState("")
  const [totalTaxable, setTotalTaxable] = useState("")
  const [totalVAT, setTotalVAT] = useState("")
  const [totalAll, setTotalAll] = useState("")
  const [list, setList] = useState([])
  const [customerid, setCustomerid] = useState("")
  const [timeStamp, setTimeStamp] = useState("")
  const componentRef = useRef();
  const [customers, setCustomers] = useState()

  const customerHandeler = (recordid) => {
    var index = customers.findIndex(x => x.id === recordid);
    setCustomerid(customers[index].id)
    setNewRec(prev => ({ ...prev, buyerName: customers[index].name, buyerContact: customers[index].contact, buyerBuildingNumber: customers[index].buildingNumber, buyerStreetName: customers[index].streetName, buyerDistrict: customers[index].district, buyerCity: customers[index].city, buyerCountry: customers[index].country, buyerPostalCode: customers[index].postalCode, buyerAdditionalNo: customers[index].additionalNo, buyerVATNumber: customers[index].VATNumber, otherBuyerID: customers[index].otherid, balance: customers[index].accountbalance }))
  }

  const saveRec = () => {
    if (totalAll <= 0) {
      alert("يجب تعبئة الفاتورة بشكل كامل .... ")
    } else {
        const invRef = collection(db, 'sales');
        const rec = {
          ...newRec,
          list, totalAmounts, totalDiscounts, totalTaxable, totalVAT, totalAll, createdAt: serverTimestamp()
        };

        addDoc(invRef, rec).then(async () => {
          const customerRef = doc(db, "customers", customerid);
          await updateDoc(customerRef, { accountbalance: increment(- Number(totalAll)) });
          history.push("/sales");
          setNewRec(prevRec => ({ ...prevRec, invoiceNumber: prevRec.invoiceNumber + 1 || 1 }))
        }).catch(err => alert(err))
      }
  }

  const getTLVForValue = (tagNum, tagValue) => {
    let tagBuf = Buffer.from([tagNum], 'utf8');
    let tagValueLenBuf = Buffer.from([tagValue.length], 'utf8');
    let tagValueBuf = Buffer.from(tagValue, 'utf8');
    let bufsArray = [tagBuf, tagValueLenBuf, tagValueBuf]
    return Buffer.concat(bufsArray)
  }

  const tlv = (seller_name, vat_number, time_stamp, Invoice_amount, vat_amount) => {
    let sellerNameBuf = getTLVForValue('1', seller_name);
    let vatRegistrationNameBuf = getTLVForValue('2', vat_number);
    let timeStampBuf = getTLVForValue('3', time_stamp);
    let taxTotalNameBuf = getTLVForValue('4', Invoice_amount)
    let vatTotalBuf = getTLVForValue('5', vat_amount);
    let tagsBufsArray = [sellerNameBuf, vatRegistrationNameBuf, timeStampBuf, taxTotalNameBuf, vatTotalBuf];
    let qrCodeBuf = Buffer.concat(tagsBufsArray);
    let qrCodeB64 = qrCodeBuf.toString('base64')
    setUrl(qrCodeB64)
  }

  const getInformation = async () => {
    const informationRec = collection(db, 'informations');
    let informationquery = query(informationRec, orderBy('createdAt'), limitToLast(1))

    const customerRec = collection(db, 'customers');
    let customerquery = query(customerRec, orderBy('createdAt'))

    const invRef = collection(db, 'sales');
    let q = query(invRef, orderBy('createdAt'), limitToLast(1))

    let promises = [getDocs(informationquery), getDocs(customerquery), getDocs(q)];
    let [informations, customers, sales] = await Promise.all(promises).then(values => values.map(ar => ar.docs.map(doc => ({ ...doc.data(), id: doc.id }))))

    setCustomers(customers)

    let minvoiceNumber;
    if (sales.length > 0) {
      minvoiceNumber = sales[0].invoiceNumber;
      minvoiceNumber = minvoiceNumber + 1 || 1;
    }
    else {
      minvoiceNumber = 1
    }
    let infoSellerRecord = informations[0]
    setNewRec(prevRec => ({ ...prevRec, invoiceNumber: minvoiceNumber, sellerName: infoSellerRecord.name, sellerContact: infoSellerRecord.contact, sellerBuildingNumber: infoSellerRecord.buildingNumber, sellerStreetName: infoSellerRecord.streetName, sellerDistrict: infoSellerRecord.district, sellerCity: infoSellerRecord.city, sellerCountry: infoSellerRecord.country, sellerPostalCode: infoSellerRecord.postalCode, sellerAdditionalNo: infoSellerRecord.additionalNo, sellerVATNumber: infoSellerRecord.VATNumber, otherSellerID: infoSellerRecord.otherid }));
  }

  useLayoutEffect(() => {
    getInformation()
  }, [])

  useEffect(() => {
    let sumTaxableAmount = list.reduce((acc, item) => acc += item.taxableAmount, 0);
    let sumTotalDiscounts = list.reduce((acc, item) => acc += item.discounts, 0);
    let sumTotalTaxable = list.reduce((acc, item) => acc += item.taxableAmount, 0);
    let sumTotalVAT = list.reduce((acc, item) => acc += item.taxAmount, 0);
    let sumItemTotalVAT = list.reduce((acc, item) => acc += item.itemTotalVAT, 0);

    setTotalAmounts(sumTaxableAmount)
    setTotalDiscounts(sumTotalDiscounts)
    setTotalTaxable(sumTotalTaxable)
    setTotalVAT(sumTotalVAT)
    setTotalAll(sumItemTotalVAT)
    if (Number(sumItemTotalVAT) > Number(newRec.balance)) {
      setBalanceMore(true)
    }
  }, [list])

  useEffect(() => {
    if (id) {
      const docRef = doc(db, "sales", id);
      getDoc(docRef).then(snap => {
        const { list, ...lRec } = snap.data()
        setTimeStamp(snap.data().createdAt.toDate())
        setList(list)
        setNewRec(lRec)
        setShowInvoice(true)
        if (newRec.sellerName && newRec.sellerVATNumber && timeStamp && totalAll && totalVAT) {
          tlv(newRec.sellerName,
            newRec.sellerVATNumber,
            dayjs(timeStamp).toISOString(),
            String(totalAll),
            String(totalVAT)
          )
        }
      })
    }
  }, [id, showInvoice])

  return (
    <div>
      <div className="container mt-1 mb-1 allpage">
        <>
          {showInvoice ? (
            <>
              <div ref={componentRef} >

                <article style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="row col-12 p-2">
                    <div className="col-5  p-2">
                      <div className="row">
                        <div className="col-6"> رقم الفاتورة   : </div>
                        <div className="col-6 float-end">{newRec.invoiceNumber}</div>
                      </div>
                      <div className="row">
                        <div className="col-6"> تاريخ الاصدار : </div>
                        <div className="col-6 float-end">{newRec.invoiceIssueDate}</div>
                      </div>
                      <div className="row">
                        <div className="col-6"> تاريخ التوريد  : </div>
                        <div className="col-6 float-end"> {newRec.dateOfSupply}</div>
                      </div>
                    </div>
                    <div className="justify-center col-4">
                      <div className="justify-center">
                        <img src={logo} width={100} height={100} alt="Rasana Al-Omran" className="justify-center" />
                      </div>
                      <div>
                        <h4> فاتورة ضريبية </h4>
                      </div>
                    </div>
                    <div className="justify-center col-3 float-start">
                      <QRCode value={url} />
                    </div>
                  </div>
                </article>



                <article >
                  <table style={{ display: 'grid' }}>
                    <thead>
                      <tr className="row justify-center">
                        <th className="textCenter border0">بيانات المورد</th>
                        <th className="textCenter border0">بيانات العميل</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="row">
                        <td className="textCenter border0 borderLeft">
                          {newRec.sellerName}
                        </td>
                        <td className="textCenter border0 borderLeft">
                          {newRec.sellerContact}
                        </td>
                        <td className="textCenter border0 borderLeft">
                         {newRec.buyerName}
                        </td>
                        <td className="textCenter border0">
                         {newRec.buyerContact}
                        </td>
                      </tr>

                      <tr className="row">
                        <td className="textCenter border0 borderLeft">
                          المبنى : {newRec.sellerBuildingNumber} -  الشارع :  {newRec.sellerStreetName} -  الحي :  {newRec.sellerDistrict} - المدينة : {newRec.sellerCity}
                        </td>
                        <td className="textCenter border0">
                          المبنى : {newRec.buyerBuildingNumber} -  الشارع :  {newRec.buyerStreetName} - الحي :  {newRec.buyerDistrict} -  المدينة : {newRec.buyerCity}
                        </td>
                      </tr>

                      <tr className="row">
                        <td className="textCenter border0 borderLeft">
                          الرمز البريدي : {newRec.sellerPostalCode} -  الرقم الإضافي  :  {newRec.sellerAdditionalNo} -  البلد :  {newRec.sellerCountry}
                        </td>
                        <td className="textCenter border0">
                          الرمز البريدي : {newRec.buyerPostalCode} -  الرقم الإضافي  :  {newRec.buyerAdditionalNo} - البلد :  {newRec.buyerCountry}
                        </td>
                      </tr>
                      <tr className="row" >
                        <td className="textCenter border0 borderLeft">
                          الرقم الضريبي: {newRec.sellerVATNumber}
                        </td>
                        <td className="textCenter border0">
                          الرقم الضريبي: {newRec.buyerVATNumber}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </article>
                <dic className="divider2"></dic>
                <article className="p-1">
                  <table className="p-1" width="100%" style={{ display: 'grid' }} >
                    <thead>
                      <tr className="row">
                        <td className="col-4 textCenter borderAll">الوصف</td>
                        <td className="col-2 textCenter borderAll">الاجمالي</td>
                        <td className="col-1 textCenter borderAll">الخصم</td>
                        <td className="col-1 textCenter borderAll">نسبة الضريبة</td>
                        <td className="col-2 textCenter borderAll">مبلغ الضريبة</td>
                        <td className="col-2 textCenter borderAll">المجموع</td>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map(({ id, item, unitPrice, description, quantity, taxableAmount, discounts, taxRate, taxAmount, itemTotalVAT }) => (
                        <tr className="row" key={id}>
                          <td className="col-4 textCenter borderAll">{description}</td>
                          <td className="col-2 textCenter borderAll">{taxableAmount}</td>
                          <td className="col-1 textCenter borderAll">{discounts}</td>
                          <td className="col-1 textCenter borderAll">{taxRate}</td>
                          <td className="col-2 textCenter borderAll">{taxAmount}</td>
                          <td className="col-2 textCenter borderAll">{itemTotalVAT}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <table className="p-1" width="100%" style={{ display: 'grid' }}>
                    <tbody>
                      <tr className="row">
                        <td className="textCenter borderAll">SAR. {totalAmounts}</td>
                        <td className="borderAll">الاجمالي ( غير شامل ضريبة القيمة المضافة )</td>
                      </tr>
                      <tr className="row">
                        <td className="textCenter borderAll">SAR. {totalDiscounts} </td>
                        <td className="borderAll">مجموع الخصومات</td>
                      </tr>
                      <tr className="row">
                        <td className="textCenter borderAll">SAR. {totalTaxable} </td>
                        <td className="borderAll">الاجمالي الخاضع للضريبة ( غير شاملة ضريبة القيمة المضافة )</td>
                      </tr>
                      <tr className="row">
                        <td className="textCenter borderAll">SAR. {totalVAT} </td>
                        <td className="borderAll">مجموع ضريبة القيمة المضافة</td>
                      </tr>
                      <tr className="row">
                        <td className="textCenter borderAll">SAR. {totalAll} </td>
                        <td className="borderAll">اجمالي المبلغ المستحق</td>
                      </tr>
                    </tbody>
                  </table>
                </article>

              </div>
              <div className="d-flex justify-content-center gap-3 mt-1 mb-1">
                <ReactToPrint copyStyles={true} trigger={() => (<button className="btn btn-primary m-1 p-2"><i className="bi bi-printer" /> Print / Download - طباعة / تنزيل </button>)} content={() => componentRef.current} />
                <Link to={`../sales`} className="btn btn-danger m-1 p-2">
                <i className="bi bi-x-circle" /> Close - أغلاق
                </Link>

              </div>

            </>

          ) : (
            <>
              <div className="mt-1 mb-1">
                <article style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <label htmlFor="invoiceNumber">رقم الفاتورة (Invoice Number)</label>
                    <input
                      type="text"
                      name="invoiceNumber"
                      id="invoiceNumber"
                      placeholder=""
                      autoComplete="off"
                      disabled
                      value={newRec?.invoiceNumber}
                      onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                    />
                  </div>
                  <div>
                    <label htmlFor="invoiceIssueDate">تاريخ الاصدار (Issue Date)</label>
                    <input
                      type="date"
                      name="invoiceIssueDate"
                      id="invoiceIssueDate"
                      placeholder=""
                      autoComplete="off"
                      value={newRec?.invoiceIssueDate}
                      onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                    />

                  </div>

                  <div>
                    <label htmlFor="dateOfSupply">تاريخ التوريد (Date of Supply) </label>
                    <input
                      type="date"
                      name="dateOfSupply"
                      id="dateOfSupply"
                      placeholder=""
                      autoComplete="off"
                      value={newRec?.dateOfSupply}
                      onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                    />
                  </div>
                </article>
                <article className="mt-1 mb-1">

                  <table style={{ display: 'grid' }}>
                    <thead>
                      <tr className="row">
                        <th >بيانات المورد (Seller) </th>
                        <th >بيانات العميل (Buyer)
                          <div className="form-group row">
                            <label htmlFor="customer" className="col-sm-5 control-label">Customer Name : </label>
                            <div className="col-sm-7">
                              <select placeholder="Select customer name" className="form-control" onChange={(e) => customerHandeler(e.target.value)} >
                                <option hidden value="">أختر اسم العميل - choice customer</option>
                                {customers && customers.length > 0 && customers.map(r => (<option key={r.id} value={r.id}> {r.name}
                                </option>))}
                              </select>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="sellerName">الاسم (Name) : </label>
                          <input
                            type="text"
                            name="sellerName"
                            id="sellerName"
                            autoComplete="off"
                            value={newRec?.sellerName}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="buyerName">الاسم (Name) : </label>
                          <input
                            type="text"
                            name="buyerName"
                            id="buyerName"
                            autoComplete="off"
                            value={newRec?.buyerName}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }} >
                          <label htmlFor="sellerContact">رقم التواصل (Contact) : </label>
                          <input
                            type="text"
                            name="sellerContact"
                            id="sellerContact"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.sellerContact}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="buyerContact">رقم التواصل (Contact) : </label>
                          <input
                            type="text"
                            name="buyerContact"
                            id="buyerContact"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.buyerContact}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="sellerBuildingNumber">رقم المبنى (Building Number) : </label>
                          <input
                            type="text"
                            name="sellerBuildingNumber"
                            id="sellerBuildingNumber"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.sellerBuildingNumber}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="buyerBuildingNumber">رقم المبنى (Building Number) : </label>
                          <input
                            type="text"
                            name="buyerBuildingNumber"
                            id="buyerBuildingNumber"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.buyerBuildingNumber}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>

                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="sellerStreetName"> اسم الشارع (Street Name) : </label>
                          <input
                            type="text"
                            name="sellerStreetName"
                            id="sellerStreetName"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.sellerStreetName}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="buyerStreetName"> اسم الشارع (Street Name) : </label>
                          <input
                            type="text"
                            name="buyerStreetName"
                            id="buyerStreetName"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.buyerStreetName}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="sellerDistrict"> الحي (District) : </label>
                          <input
                            type="text"
                            name="sellerDistrict"
                            id="sellerDistrict"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.sellerDistrict}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="buyerDistrict"> الحي (District) : </label>
                          <input
                            type="text"
                            name="buyerDistrict"
                            id="buyerDistrict"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.buyerDistrict}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="sellerCity"> المدينة (City) : </label>
                          <input
                            type="text"
                            name="sellerCity"
                            id="sellerCity"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.sellerCity}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />

                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="buyerCity"> المدينة (City) : </label>
                          <input
                            type="text"
                            name="buyerCity"
                            id="buyerCity"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.buyerCity}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />

                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="sellerCountry"> البلد (Country) : </label>
                          <input
                            type="text"
                            name="sellerCountry"
                            id="sellerCountry"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.sellerCountry}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="buyerCountry"> البلد (Country) : </label>
                          <input
                            type="text"
                            name="buyerCountry"
                            id="buyerCountry"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.buyerCountry}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="sellerPostalCode"> الرمز البريدي (Postal Code) : </label>
                          <input
                            type="text"
                            name="sellerPostalCode"
                            id="sellerPostalCode"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.sellerPostalCode}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="buyerPostalCode"> الرمز البريدي (Postal Code) : </label>
                          <input
                            type="text"
                            name="buyerPostalCode"
                            id="buyerPostalCode"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.buyerPostalCode}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="sellerAdditionalNo"> الرقم الإضافي للعنوان (Additional No) : </label>
                          <input
                            type="text"
                            name="sellerAdditionalNo"
                            id="sellerAdditionalNo"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.sellerAdditionalNo}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="buyerAdditionalNo"> الرقم الإضافي للعنوان (Additional No) : </label>
                          <input
                            type="text"
                            name="buyerAdditionalNo"
                            id="buyerAdditionalNo"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.buyerAdditionalNo}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="sellerVATNumber"> الرقم الضريبي(VAT Number) : </label>
                          <input
                            type="text"
                            name="sellerVATNumber"
                            id="sellerVATNumber"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.sellerVATNumber}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />

                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="buyerVATNumber"> الرقم الضريبي(VAT Number) : </label>
                          <input
                            type="text"
                            name="buyerVATNumber"
                            id="buyerVATNumber"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.buyerVATNumber}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />

                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="otherSellerID"> معرف آخر (Other Seller ID) : </label>
                          <input
                            type="text"
                            name="otherSellerID"
                            id="otherSellerID"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.otherSellerID}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />

                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="otherBuyerID"> معرف آخر (Other Buyer ID) : </label>
                          <input
                            type="text"
                            name="otherBuyerID"
                            id="otherBuyerID"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.otherBuyerID}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td>
                          {balanceMore ? (<div className="alert alert-danger">
                            إجمالي الفاتورة أكبر من رصيد الحساب
                            The total bill is greater than the account balance
                          </div>) : null}
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="balance"> رصيد الحساب (Account balance) : </label>
                          <input
                            type="text"
                            disabled
                            name="balance"
                            id="balance"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.balance}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </article>
                <article className="mt-2 mb-2">
                  <TableForm
                    list={list}
                    setList={setList}
                  />
                  <table width="100%" style={{ display: 'grid' }} >
                    <tbody>
                      <tr style={{ display: 'flex', justifyContent: "space-between" }}>
                        <td>SAR. {totalAmounts}</td>
                        <td>الاجمالي ( غير شامل ضريبة القيمة المضافة )</td>
                        <td>Total ( Excluding VAT )</td>
                      </tr>
                      <tr style={{ display: 'flex', justifyContent: "space-between" }}>
                        <td>SAR. {totalDiscounts} </td>
                        <td>مجموع الخصومات</td>
                        <td>Discount</td>
                      </tr>
                      <tr style={{ display: 'flex', justifyContent: "space-between" }}>
                        <td>SAR. {totalTaxable} </td>
                        <td>الاجمالي الخاضع للضريبة ( غير شاملة ضريبة القيمة المضافة )</td>
                        <td>Total Taxable Amount (Excluding VAT)</td>
                      </tr>
                      <tr style={{ display: 'flex', justifyContent: "space-between" }}>
                        <td>SAR. {totalVAT} </td>
                        <td>مجموع ضريبة القيمة المضافة</td>
                        <td>Total VAT</td>
                      </tr>
                      <tr style={{ display: 'flex', justifyContent: "space-between" }}>
                        <td>SAR. {totalAll} </td>
                        <td>اجمالي المبلغ المستحق</td>
                        <td>Total Amount Due</td>
                      </tr>
                    </tbody>
                  </table>
                </article>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <button className="button" onClick={() => setShowInvoice(true)} >
                    عرض الفاتورة Preview Invoice
                  </button>
                  <Link to={`../sales`} className="btn btn-danger m-1 p-2">
                    أغلاق الصفحة Close Page
                  </Link>
                  <button className="button" onClick={saveRec} >
                    حــفظ الفاتورة Save to Server
                  </button>
                </div>
              </div>
            </>
          )
          }
        </>
      </div>
    </div>
  )
}

export default NewSales
