
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { database } from '../../firebase'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { useAuth } from "../../contexts/AuthContext"


function Vendors() {

    const [data, setData] = useState([])
    const [name, setName] = useState('')
    const [vendorid, setVendorid] = useState('')
    const [contact, setContact] = useState('')
    const [buildingNumber, setBuildingNumber] = useState('')
    const [streetName, setStreetName] = useState('')
    const [district, setDistrict] = useState('')
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [additionalNo, setAdditionalNo] = useState('')
    const [VATNumber, setVATNumber] = useState('')
    const [otherid, setOtherid] = useState('')
    const [accountid, setAccountid] = useState(0)
    const [accountbalance, setAccountbalance] = useState(0)
    const [userid, setUserid] = useState('')
    const [users, setUsers] = useState([])

    const [showvendorsAll, setshowvendorsAll] = useState(true)
    const [showFormvendors, setshowFormvendors] = useState(false)

    const [idEdit, setIdEdit] = useState(null)
    const [error, setError] = useState("")
    const { currentUser } = useAuth()

    useEffect(() => {
        let vendors = null;
        try {
            vendors = database.vendors.onSnapshot(res => {
                let m = res.docs.map(rec => database.formatDoc(rec))
                setData(m)
            })
            database.users.where('roles', '==', 'vendors').limit(20).get().then(res => {
                let records = res.docs.map(rec => database.formatDoc(rec))
                setUsers(records)
            })
        } catch {
            setError("Failed to read data ... ")
        } finally {

        }
        return vendors
    }, [])

    const openRecord = async () => {
        setIdEdit(null)
        setName('')
        setVendorid('')
        setContact('')
        setBuildingNumber('')
        setStreetName('')
        setDistrict('')
        setCity('')
        setCountry('')
        setPostalCode('')
        setAdditionalNo('')
        setVATNumber('')
        setOtherid('')
        setAccountid('')
        setAccountbalance(0)
        setUserid(0)

        setshowvendorsAll(false);
        setshowFormvendors(true);
    }

    const saveRecord = async () => {
        if (idEdit) {
            await database.vendors.doc(idEdit).update({ name, buildingNumber, contact, streetName, district, city, country, postalCode, additionalNo, VATNumber, otherid, vendorid, accountid, accountbalance: Number(accountbalance), userid, updatedBy: currentUser.uid, updatedAt: database.getCurrentTimestamp() })
            setIdEdit(null)

        } else {
            await database.vendors.add({ name, buildingNumber, contact, streetName, district, city, country, postalCode, additionalNo, VATNumber, otherid, vendorid, accountid, accountbalance: Number(accountbalance), userid, createdBy: currentUser.uid, createdAt: database.getCurrentTimestamp() })
        }

        setName('')
        setVendorid('')
        setContact('')
        setBuildingNumber('')
        setStreetName('')
        setDistrict(0)
        setCity('')
        setCountry('')
        setPostalCode('')
        setAdditionalNo('')
        setVATNumber('')
        setOtherid('')
        setAccountid('')
        setAccountbalance(0)
        setUserid('')

        setshowvendorsAll(true);
        setshowFormvendors(false);
    }

    const editRecord = async (record) => {

        setshowvendorsAll(false);
        setshowFormvendors(true);
        setIdEdit(record.id)

        setName(record.name)
        setVendorid(record.vendorid)
        setContact(record.contact)
        setBuildingNumber(record.buildingNumber)
        setStreetName(record.streetName)
        setDistrict(record.district)
        setCity(record.city)
        setCountry(record.country)
        setPostalCode(record.postalCode)
        setAdditionalNo(record.additionalNo)
        setVATNumber(record.VATNumber)
        setOtherid(record.otherid)
        setAccountid(record.accountid)
        setAccountbalance(record.accountbalance)
        setUserid(record.userid)

    }

    const closeRecord = async () => {

        setName('')
        setVendorid('')
        setContact('')
        setBuildingNumber('')
        setStreetName('')
        setDistrict(0)
        setCity('')
        setCountry('')
        setPostalCode('')
        setAdditionalNo('')
        setVATNumber('')
        setOtherid('')
        setAccountid('')
        setAccountbalance(0)
        setUserid('')

        setshowvendorsAll(true);
        setshowFormvendors(false);
    }

    const delRecord = async (id) => {
        var r = window.confirm(`هل أنت متأكد من حذف السجل ${id}`)
        if (r) await database.vendors.doc(id).delete()
    }

    return (
        <div>
            <Header />
            <div className="container mt-1 mb-1">
                {error && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>خطأ...</strong> {error}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>}
                <div className="card text-center">
                    <div className="card-header clearfix">
                        <div className="row">
                            <div className="col-sm-2">
                                <button type="button" className="btn btn-primary" onClick={openRecord}>
                                    <i className="bi bi-plus-lg"></i> إضافة
                                </button>
                            </div>
                            <div className="col-sm-8">
                                <h4> الموردين </h4>
                            </div>
                            <div className="col-sm-2">
                                <Link to="/admin" className="btn btn-danger">
                                    أغلاق <i className="bi bi-x-circle" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table">
                            <thead>
                                <tr className="d-flex">
                                    <th className="col-3"> الأسم </th>
                                    <th className="col-2"> رقم المورد </th>
                                    <th className="col-2"> الاتصال </th>
                                    <th className="col-2"> الرصيد </th>
                                    <th className="col-3"> إدارة </th>
                                </tr>
                            </thead>
                            <tbody>
                                {showvendorsAll && data && data.map(record => (<tr className="d-flex" key={record.id}>
                                    <td className="col-3">{record.name}</td>
                                    <td className="col-2">{record.vendorid}</td>
                                    <td className="col-2">{record.contact}</td>
                                    <td className="col-2">{record.accountbalance}</td>
                                    <td className="col-3">
                                        <button className="btn btn-primary ms-1" type="button" onClick={() => editRecord(record)}>
                                            <i className="ml-1 bi bi-pencil-square" />
                                            تعديل
                                        </button>
                                        <button className="btn btn-danger ms-1" type="button" onClick={() => delRecord(record.id)}>
                                            <i className="ml-1 bi bi-x-circle" />
                                            حذف
                                        </button>
                                    </td>
                                </tr>))
                                }
                            </tbody>
                        </table>
                    </div>
                    {showFormvendors && (<div className="card-footer">

                        <div className="form-group row m-1">
                            <label htmlFor="name" className="col-sm-2 control-label">الأسم</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={name} onChange={(e) => setName(e.target.value)} placeholder="ادخل الاسم" type="text" />
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="vendorid" className="col-sm-2 control-label">رقم المورد</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={vendorid} onChange={(e) => setVendorid(e.target.value)} placeholder="ادخل رقم الموزع" type="text" />
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="contact" className="col-sm-2 control-label">رقم الاتصال</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={contact} onChange={(e) => setContact(e.target.value)} placeholder="ادخل رقم الاتصال" type="text" />
                            </div>
                        </div>
                        <div className="form-group row m-1">
                            <label htmlFor="buildingNumber" className="col-sm-2 control-label">رقم المبنى</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={buildingNumber} onChange={(e) => setBuildingNumber(e.target.value)} placeholder="ادخل رقم المبنى" type="text" />
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="streetName" className="col-sm-2 control-label">اسم الشارع</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={streetName} onChange={(e) => setStreetName(e.target.value)} placeholder="ادخل اسم الشارع" type="text" />
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="district" className="col-sm-2 control-label"> المنطقة </label>
                            <div className="col-sm-8">
                                <input className="form-control" value={district} onChange={(e) => setDistrict(e.target.value)} placeholder="ادخل المنطقة" type="text" />
                            </div>
                        </div>


                        <div className="form-group row m-1">
                            <label htmlFor="city" className="col-sm-2 control-label"> المدينة </label>
                            <div className="col-sm-8">
                                <input className="form-control" value={city} onChange={(e) => setCity(e.target.value)} placeholder="ادخل المدينة" type="text" />
                            </div>
                        </div>


                        <div className="form-group row m-1">
                            <label htmlFor="country" className="col-sm-2 control-label"> الدولة </label>
                            <div className="col-sm-8">
                                <input className="form-control" value={country} onChange={(e) => setCountry(e.target.value)} placeholder="ادخل الدولة" type="text" />
                            </div>
                        </div>


                        <div className="form-group row m-1">
                            <label htmlFor="postalCode" className="col-sm-2 control-label"> الرمز البريدي </label>
                            <div className="col-sm-8">
                                <input className="form-control" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} placeholder="ادخل الرمز البريدي" type="text" />
                            </div>
                        </div>


                        <div className="form-group row m-1">
                            <label htmlFor="additionalNo" className="col-sm-2 control-label"> الرقم الإضافي </label>
                            <div className="col-sm-8">
                                <input className="form-control" value={additionalNo} onChange={(e) => setAdditionalNo(e.target.value)} placeholder="ادخل الرقم الإضافي" type="text" />
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="VATNumber" className="col-sm-2 control-label"> رقم الضريبة </label>
                            <div className="col-sm-8">
                                <input className="form-control" value={VATNumber} onChange={(e) => setVATNumber(e.target.value)} placeholder="ادخل الرقم الضريبي" type="text" />
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="otherid" className="col-sm-2 control-label"> اي ارقام اخرى </label>
                            <div className="col-sm-8">
                                <input className="form-control" value={otherid} onChange={(e) => setOtherid(e.target.value)} placeholder="ادخل اي ارقام آخرى" type="text" />
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="accountid" className="col-sm-2 control-label">رقم الحساب</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={accountid} onChange={(e) => setAccountid(e.target.value)} placeholder="ادخل رقم الحساب" type="text" />
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="accountbalance" className="col-sm-2 control-label">رصيد الحساب</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={accountbalance} onChange={(e) => setAccountbalance(e.target.value)} placeholder="ادخل رصيد الحساب" type="number" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="userid" className="col-sm-2 control-label">اسم المستخدم</label>
                            <div className="col-sm-8">
                                <select placeholder="Select User id" className="form-control" value={userid} onChange={(e) => setUserid(e.target.value)} >
                                    {users && users.length > 0 && users.map(r => (<option key={r.id} value={r.id}> {r.username}
                                    </option>))}
                                </select>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-sm-offset-2 col-sm-10 m-1">
                                <button className="btn btn-primary m-1" onClick={saveRecord}> <i className="bi bi-pencil-square" /> حفظ </button>
                                <button className="btn btn-danger m-1" onClick={closeRecord}> <i className="bi bi-x-circle" /> أغلاق </button>
                            </div>
                        </div>

                    </div>)}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Vendors