import React from 'react'
import './Menu.css'
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import logo from '../../assets/images/logo.jpg';
import { useAuth } from "../../contexts/AuthContext"

function Menu(props) {
    const { currentUser, user } = useAuth()
    const { logout } = useAuth()

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light static-top">
            <div className="container">
                <Link to="/" className="navbar-brand">
                    <img src={logo} width={70} height={90} alt="acetaamir" className="d-inline-block align-top" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-nav" aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end align-center" id="main-nav">
                    <ul className="navbar-nav ms-auto">
                    <li className="nav-item active">
                            <Link to="/" className="nav-link text-dark"> الرئيسة </Link>
                        </li>
                        <li className="nav-item active">
                            <Link to="/" className="nav-link text-dark"> من نحن </Link>
                        </li>
                        <li className="nav-item active">
                            <Link to="/" className="nav-link text-dark"> مجالات العمل </Link>
                        </li>
                        {user?.roles === 'admin' && <li className="nav-item">
                            <Link to="/admin" className="nav-link text-dark"> الإدارة </Link>
                        </li>}
                    </ul>
                    <ul className="nav navbar-nav ml-auto">
                        <li className="nav-item">
                            {!currentUser && <Link to="/signin" className="btn btn-outline-dark"> تسجيل الدخول </Link>}
                            {currentUser && <Button className="btn btn-outline-dark" onClick={logout}> تسجيل الخروج </Button>}
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
export default Menu
