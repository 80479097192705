import React, { useState, useEffect } from 'react'
import { database } from '../../firebase'
import './Purchases.css'
import { Link } from "react-router-dom"
import Header from '../header/Header'
import Footer from '../footer/Footer'

function Purchases() {
    const [data, setData] = useState([])
    const [error, setError] = useState()
    useEffect(() => {
        let purchases = null;
        try {
            database.purchases.orderBy('invoiceNumber').limit(20).get().then(res => {
                let records = res.docs.map(rec => database.formatDoc(rec))
                setData(records)
            })
        } catch {
            setError("Failed to read data ... ")
        } finally {

        }
        return purchases
    }, [])

    const displayRecord = async (record) => {
    }

    const closeRecord = async () => {
    }

    const delRecord = async (id) => {
    }

    return (
        <div>
            <Header />

            <div className="container mt-1 mb-1">

                <div className="card text-center">
                    <div className="card-header clearfix">
                        <div className="row">
                            <div className="col-sm-1">
                                <Link to={`newpurchases`} className="btn btn-primary">
                                    <i className="bi bi-plus-lg"></i> Add
                                </Link>
                            </div>
                            <div className="col-sm-9">
                                <h4>الفاتورة الضريبية</h4>
                                <h4><span>Tax Invoice</span></h4>
                            </div>
                            <div className="col-sm-2">
                                <Link to="/admin" className="btn btn-danger">
                                    Close <i className="bi bi-x-circle" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <table width="100%" className="table">
                            <thead>
                                <tr className="d-flex">
                                    <th className="col-2"> رقم الفاتورة </th>
                                    <th className="col-2"> تاريخ الاصدار </th>
                                    <th className="col-2"> تاريخ التوريد </th>
                                    <th className="col-2"> اسم المورد </th>
                                    <th className="col-2"> رقم التواصل </th>
                                    <th className="col-2"> إدارة </th>
                                </tr>
                                <tr className="d-flex">
                                    <th className="col-2"> <span>Invoice Number</span> </th>
                                    <th className="col-2"> <span>Invoice IssueDate</span> </th>
                                    <th className="col-2"> <span>Date Of Supply</span></th>
                                    <th className="col-2"> <span>Vendor Name</span></th>
                                    <th className="col-2"> <span>Vendor Contact</span></th>
                                    <th className="col-2"> <span>Manage</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.map(record => (<tr className="d-flex" key={record.id}>
                                    <td className="col-2">{record.invoiceNumber}</td>
                                    <td className="col-2">{record.invoiceIssueDate}</td>
                                    <td className="col-2">{record.dateOfSupply}</td>
                                    <td className="col-2">{record.vendorName}</td>
                                    <td className="col-2">{record.vendorContact}</td>
                                    <td className="col-2">
                                        <Link to={`newpurchases/${record.id}`} className="btn btn-primary ms-1">
                                            <i className="ml-1 bi bi-pencil-square" />
                                            Display
                                        </Link>
                                    </td>
                                </tr>))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default Purchases