import { useState, useRef, useEffect, useLayoutEffect } from "react"
import TableForm from "./TableForm"
import ReactToPrint from "react-to-print"
import QRCode from 'qrcode.react';
import logo from '../../assets/images/logo.jpg';
import { db } from '../../firebase';
import { useParams } from "react-router-dom";
import './NewPurchases.css'
import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom";
import dayjs from 'dayjs'

import { addDoc, updateDoc, collection, serverTimestamp, getDocs, getDoc, doc, query, orderBy, limitToLast, increment } from 'firebase/firestore'
const iniRec = { invoiceNumber: "", invoiceIssueDate: new Date().toISOString().slice(0, 10), dateOfSupply: new Date().toISOString().slice(0, 10), companyName: "", vendorName: "", vendorContact: "", companyContact: "", companyBuildingNumber: "", vendorBuildingNumber: "", companyStreetName: "", vendorStreetName: "", companyDistrict: "", vendorDistrict: "", companyCity: "", vendorCity: "", companyCountry: "", vendorCountry: "", companyPostalCode: "", vendorPostalCode: "", companyAdditionalNo: "", vendorAdditionalNo: "", companyVATNumber: "", vendorVATNumber: "", otherCompanyID: "", otherVendorID: "" }

function NewPurchases() {
  const history = useHistory();
  const { id } = useParams()
  const [newRec, setNewRec] = useState(() => iniRec);
  const [showInvoice, setShowInvoice] = useState(false)
  const [balanceMore, setBalanceMore] = useState(false)
  const [totalAmounts, setTotalAmounts] = useState("")
  const [totalDiscounts, setTotalDiscounts] = useState("")
  const [totalTaxable, setTotalTaxable] = useState("")
  const [totalVAT, setTotalVAT] = useState("")
  const [totalAll, setTotalAll] = useState("")
  const [list, setList] = useState([])
  const [vendorid, setVendorid] = useState("")
  const [timeStamp, setTimeStamp] = useState("")
  const [url, setUrl] = useState("");
  const componentRef = useRef();
  const [vendors, setVendors] = useState()

  const vendorHandeler = (recordid) => {
    var index = vendors.findIndex(x => x.id === recordid);
    setVendorid(vendors[index].id)
    setNewRec(prev => ({ ...prev, vendorName: vendors[index].name, vendorContact: vendors[index].contact, vendorBuildingNumber: vendors[index].buildingNumber, vendorStreetName: vendors[index].streetName, vendorDistrict: vendors[index].district, vendorCity: vendors[index].city, vendorCountry: vendors[index].country, vendorPostalCode: vendors[index].postalCode, vendorAdditionalNo: vendors[index].additionalNo, vendorVATNumber: vendors[index].VATNumber, otherVendorID: vendors[index].otherid , balance: vendors[index].accountbalance}))
  }

  const saveRec = () => {
    const invRef = collection(db, 'purchases');
    const rec = {
      ...newRec,
      list, totalAmounts, totalDiscounts, totalTaxable, totalVAT, totalAll, createdAt: serverTimestamp()
    };

    addDoc(invRef, rec).then(async () => {
      const vendorRef = doc(db, "vendors", vendorid);
      await updateDoc(vendorRef, { accountbalance: increment(- Number(totalAll)) });
      history.push("/purchases");
      setNewRec(prevRec => ({ ...prevRec, invoiceNumber: prevRec.invoiceNumber + 1 || 1 }))
    }).catch(err => alert(err))
  }

  const getTLVForValue = (tagNum, tagValue) => {
    let tagBuf = Buffer.from([tagNum], 'utf8');
    let tagValueLenBuf = Buffer.from([tagValue.length], 'utf8');
    let tagValueBuf = Buffer.from(tagValue, 'utf8');
    let bufsArray = [tagBuf, tagValueLenBuf, tagValueBuf]
    return Buffer.concat(bufsArray)
  }

  const tlv = (vendor_name, vat_number, time_stamp, Invoice_amount, vat_amount) => {
    let vendorNameBuf = getTLVForValue('1', vendor_name);
    let vatRegistrationNameBuf = getTLVForValue('2', vat_number);
    let timeStampBuf = getTLVForValue('3', time_stamp);
    let taxTotalNameBuf = getTLVForValue('4', Invoice_amount)
    let vatTotalBuf = getTLVForValue(5, vat_amount);
    let tagsBufsArray = [vendorNameBuf, vatRegistrationNameBuf, timeStampBuf, taxTotalNameBuf, vatTotalBuf];
    let qrCodeBuf = Buffer.concat(tagsBufsArray);
    let qrCodeB64 = qrCodeBuf.toString('base64')
    setUrl(qrCodeB64)
  }

  const getInformation = async () => {
    const informationRec = collection(db, 'informations');
    let informationquery = query(informationRec, orderBy('createdAt'), limitToLast(1))

    const vendorRec = collection(db, 'vendors');
    let vendorquery = query(vendorRec, orderBy('createdAt'))

    const invRef = collection(db, 'purchases');
    let q = query(invRef, orderBy('createdAt'), limitToLast(1))

    let promises = [getDocs(informationquery), getDocs(vendorquery), getDocs(q)];
    let [informations, vendors, purchases] = await Promise.all(promises).then(values => values.map(ar => ar.docs.map(doc => ({ ...doc.data(), id: doc.id }))))

    setVendors(vendors)
    let minvoiceNumber;
    if (purchases.length > 0) {
      minvoiceNumber = purchases[0].invoiceNumber;
      minvoiceNumber = minvoiceNumber + 1 || 1;
    }
    else {
      minvoiceNumber = 1
    }
    let infoCompanyRecord = informations[0]
    setNewRec(prevRec => ({ ...prevRec, invoiceNumber: minvoiceNumber, companyName: infoCompanyRecord.name, companyContact: infoCompanyRecord.contact, companyBuildingNumber: infoCompanyRecord.buildingNumber, companyStreetName: infoCompanyRecord.streetName, companyDistrict: infoCompanyRecord.district, companyCity: infoCompanyRecord.city, companyCountry: infoCompanyRecord.country, companyPostalCode: infoCompanyRecord.postalCode, companyAdditionalNo: infoCompanyRecord.additionalNo, companyVATNumber: infoCompanyRecord.VATNumber, otherCompanyID: infoCompanyRecord.otherid }));
  }

  useLayoutEffect(() => {
    getInformation()
  }, [])

  useEffect(() => {
    let sumTaxableAmount = list.reduce((acc, item) => acc += item.taxableAmount, 0);
    let sumTotalDiscounts = list.reduce((acc, item) => acc += item.discounts, 0);
    let sumTotalTaxable = list.reduce((acc, item) => acc += item.taxableAmount, 0);
    let sumTotalVAT = list.reduce((acc, item) => acc += item.taxAmount, 0);
    let sumItemTotalVAT = list.reduce((acc, item) => acc += item.itemTotalVAT, 0);

    setTotalAmounts(sumTaxableAmount)
    setTotalDiscounts(sumTotalDiscounts)
    setTotalTaxable(sumTotalTaxable)
    setTotalVAT(sumTotalVAT)
    setTotalAll(sumItemTotalVAT)
    if (Number(sumItemTotalVAT) > Number(newRec.balance)) {
      setBalanceMore(true)
    }
  }, [list])

  useEffect(() => {
    if (id) {
      const docRef = doc(db, "purchases", id);
      getDoc(docRef).then(snap => {
        const { list, ...lRec } = snap.data()
        setTimeStamp(snap.data().createdAt.toDate())
        setList(list)
        setNewRec(lRec)
        setShowInvoice(true)
        if (newRec.vendorName && newRec.vendorVATNumber && timeStamp && totalAll && totalVAT) {
          tlv(newRec.vendorName,
            newRec.vendorVATNumber,
            dayjs(timeStamp).toISOString(),
            String(totalAll),
            String(totalVAT)
          )
        }
      })
    }
  }, [id, showInvoice])

  return (
    <div>
      <div className="container mt-1 mb-1 allpage">
        <>
          {showInvoice ? (
            <>

              <div ref={componentRef} >

                <article style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <header >
                      <article >
                        <div>
                          <img src={logo} width={70} height={70} alt="acetaamir" />
                        </div>
                        <div>
                          <h4> فاتورة ضريبية </h4>
                          <h4> Tax Invoice  </h4>
                        </div>
                      </article>
                    </header>
                  </div>
                  <div>
                    <QRCode value={url} />
                  </div>
                </article>

                <article style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className="flex">
                    <label htmlFor="invoiceNumber">رقم الفاتورة (Invoice Number)</label>
                    <div>{newRec.invoiceNumber}</div>
                  </div>
                  <div>
                    <label htmlFor="invoiceIssueDate">تاريخ الاصدار (Issue Date)</label>
                    <div>{newRec.invoiceIssueDate}</div>
                  </div>
                  <div>
                    <label htmlFor="dateOfSupply">تاريخ التوريد (Date of Supply) </label>
                    <div>{newRec.dateOfSupply}</div>
                  </div>
                </article>

                <article >
                  <table style={{ display: 'grid' }}>
                    <thead>
                      <tr className="row">
                        <th>بيانات المورد (Company) </th>
                        <th>بيانات المصنع (Vendor)</th>

                      </tr>
                    </thead>
                    <tbody>

                      <tr className="row">
                        <td>
                          الاسم : {newRec.companyName}

                        </td>
                        <td style={{ display: 'flex', justifyContent: 'space-between' }}>
                          الاسم  : {newRec.vendorName}

                        </td>
                      </tr>
                      <tr className="row">
                        <td>
                          رقم التواصل  : {newRec.companyContact}
                        </td>
                        <td>
                          رقم التواصل  : {newRec.vendorContact}
                        </td>
                      </tr>
                      <tr className="row">
                        <td>
                          رقم المبنى  : {newRec.companyBuildingNumber}
                        </td>
                        <td>
                          رقم المبنى  : {newRec.vendorBuildingNumber}
                        </td>
                      </tr>
                      <tr className="row">
                        <td>
                          اسم الشارع  : {newRec.companyStreetName}
                        </td>
                        <td>
                          اسم الشارع  : {newRec.vendorStreetName}
                        </td>
                      </tr>
                      <tr className="row">
                        <td>
                          الحي  : {newRec.companyDistrict}
                        </td>
                        <td>
                          الحي  : {newRec.vendorDistrict}
                        </td>
                      </tr>
                      <tr className="row">
                        <td>
                          المدينة : {newRec.companyCity}
                        </td>
                        <td>
                          المدينة : {newRec.vendorCity}
                        </td>
                      </tr>
                      <tr className="row">
                        <td>
                          البلد : {newRec.companyCountry}
                        </td>
                        <td>
                          البلد : {newRec.vendorCountry}
                        </td>
                      </tr>
                      <tr className="row">
                        <td>
                          الرمز البريدي : {newRec.companyPostalCode}
                        </td>
                        <td>
                          الرمز البريدي : {newRec.vendorPostalCode}
                        </td>
                      </tr>
                      <tr className="row">
                        <td>
                          الرقم الإضافي للعنوان : {newRec.companyAdditionalNo}
                        </td>
                        <td>
                          الرقم الإضافي للعنوان : {newRec.vendorAdditionalNo}
                        </td>
                      </tr>
                      <tr className="row">
                        <td>
                          رقم تسجيل ضريبة القيمة المضافة : {newRec.companyVATNumber}
                        </td>
                        <td>
                          رقم تسجيل ضريبة القيمة المضافة : {newRec.vendorVATNumber}
                        </td>
                      </tr>
                      <tr className="row">
                        <td>
                          معرف آخر  : {newRec.otherCompanyID}
                        </td>
                        <td>
                          معرف آخر  : {newRec.otherVendorID}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </article>

                <article>
                  <table width="100%" style={{ display: 'grid' }} >
                    <thead>
                      <tr className="row">
                        <td className="col-1">المنتج</td>
                        <td className="col-1">السعر</td>
                        <td className="col-4">الوصف</td>
                        <td className="col-1">الكمية</td>
                        <td className="col-1">الاجمالي</td>
                        <td className="col-1">الخصومات</td>
                        <td className="col-1">نسبة الضريبة</td>
                        <td className="col-1">مبلغ الضريبة</td>
                        <td className="col-1">المجموع</td>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map(({ id, item, unitPrice, description, quantity, taxableAmount, discounts, taxRate, taxAmount, itemTotalVAT }) => (
                        <tr className="row" key={id}>
                          <td className="col-1">{item}</td>
                          <td className="col-1">{unitPrice}</td>
                          <td className="col-4">{description}</td>
                          <td className="col-1">{quantity}</td>
                          <td className="col-1">{taxableAmount}</td>
                          <td className="col-1">{discounts}</td>
                          <td className="col-1">{taxRate}</td>
                          <td className="col-1">{taxAmount}</td>
                          <td className="col-1">{itemTotalVAT}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <table width="100%" style={{ display: 'grid' }}>
                    <tbody>
                      <tr className="row">
                        <td >SAR. {totalAmounts}</td>
                        <td colSpan="2">الاجمالي ( غير شامل ضريبة القيمة المضافة )</td>
                      </tr>
                      <tr className="row">
                        <td >SAR. {totalDiscounts} </td>
                        <td colSpan="2">مجموع الخصومات</td>
                      </tr>
                      <tr className="row">
                        <td >SAR. {totalTaxable} </td>
                        <td colSpan="2">الاجمالي الخاضع للضريبة ( غير شاملة ضريبة القيمة المضافة )</td>
                      </tr>
                      <tr className="row">
                        <td >SAR. {totalVAT} </td>
                        <td colSpan="2">مجموع ضريبة القيمة المضافة</td>
                      </tr>
                      <tr className="row">
                        <td >SAR. {totalAll} </td>
                        <td colSpan="2">اجمالي المبلغ المستحق</td>
                      </tr>
                    </tbody>
                  </table>
                </article>

              </div>
              <div className="d-flex justify-content-center gap-3 mt-1 mb-1">
                <ReactToPrint copyStyles={true} trigger={() => (<button className="btn btn-primary m-1 p-2"> Print / Download </button>)} content={() => componentRef.current} />
                <Link to={`../purchases`} className="btn btn-danger m-1 p-2">
                  Close
                </Link>

              </div>

            </>

          ) : (
            <>
              <div className="mt-1 mb-1">
                <article style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <label htmlFor="invoiceNumber">رقم الفاتورة (Invoice Number)</label>
                    <input
                      type="text"
                      name="invoiceNumber"
                      id="invoiceNumber"
                      placeholder=""
                      autoComplete="off"
                      disabled
                      value={newRec?.invoiceNumber}
                      onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                    />
                  </div>
                  <div>
                    <label htmlFor="invoiceIssueDate">تاريخ الاصدار (Issue Date)</label>
                    <input
                      type="date"
                      name="invoiceIssueDate"
                      id="invoiceIssueDate"
                      placeholder=""
                      autoComplete="off"
                      value={newRec?.invoiceIssueDate}
                      onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                    />

                  </div>

                  <div>
                    <label htmlFor="dateOfSupply">تاريخ التوريد (Date of Supply) </label>
                    <input
                      type="date"
                      name="dateOfSupply"
                      id="dateOfSupply"
                      placeholder=""
                      autoComplete="off"
                      value={newRec?.dateOfSupply}
                      onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                    />
                  </div>
                </article>
                <article className="mt-1 mb-1">

                  <table style={{ display: 'grid' }}>
                    <thead>
                      <tr className="row">
                        <th >بيانات المصنع (Company) </th>
                        <th >بيانات المورد (Vendor)
                          <div className="form-group row">
                            <label htmlFor="vendor" className="col-sm-5 control-label">Vendor Name : </label>
                            <div className="col-sm-7">
                              <select placeholder="Select vendor name" className="form-control" onChange={(e) => vendorHandeler(e.target.value)} >
                                {vendors && vendors.length > 0 && vendors.map(r => (<option key={r.id} value={r.id}> {r.name}
                                </option>))}
                              </select>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="companyName">الاسم (Name) : </label>
                          <input
                            type="text"
                            name="companyName"
                            id="companyName"
                            autoComplete="off"
                            value={newRec?.companyName}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="vendorName">الاسم (Name) : </label>
                          <input
                            type="text"
                            name="vendorName"
                            id="vendorName"
                            autoComplete="off"
                            value={newRec?.vendorName}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }} >
                          <label htmlFor="companyContact">رقم التواصل (Contact) : </label>
                          <input
                            type="text"
                            name="companyContact"
                            id="companyContact"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.companyContact}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="vendorContact">رقم التواصل (Contact) : </label>
                          <input
                            type="text"
                            name="vendorContact"
                            id="vendorContact"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.vendorContact}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="companyBuildingNumber">رقم المبنى (Building Number) : </label>
                          <input
                            type="text"
                            name="companyBuildingNumber"
                            id="companyBuildingNumber"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.companyBuildingNumber}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="vendorBuildingNumber">رقم المبنى (Building Number) : </label>
                          <input
                            type="text"
                            name="vendorBuildingNumber"
                            id="vendorBuildingNumber"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.vendorBuildingNumber}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>

                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="companyStreetName"> اسم الشارع (Street Name) : </label>
                          <input
                            type="text"
                            name="companyStreetName"
                            id="companyStreetName"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.companyStreetName}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="vendorStreetName"> اسم الشارع (Street Name) : </label>
                          <input
                            type="text"
                            name="vendorStreetName"
                            id="vendorStreetName"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.vendorStreetName}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="companyDistrict"> الحي (District) : </label>
                          <input
                            type="text"
                            name="companyDistrict"
                            id="companyDistrict"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.companyDistrict}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="vendorDistrict"> الحي (District) : </label>
                          <input
                            type="text"
                            name="vendorDistrict"
                            id="vendorDistrict"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.vendorDistrict}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="companyCity"> المدينة (City) : </label>
                          <input
                            type="text"
                            name="companyCity"
                            id="companyCity"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.companyCity}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />

                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="vendorCity"> المدينة (City) : </label>
                          <input
                            type="text"
                            name="vendorCity"
                            id="vendorCity"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.vendorCity}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />

                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="companyCountry"> البلد (Country) : </label>
                          <input
                            type="text"
                            name="companyCountry"
                            id="companyCountry"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.companyCountry}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="vendorCountry"> البلد (Country) : </label>
                          <input
                            type="text"
                            name="vendorCountry"
                            id="vendorCountry"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.vendorCountry}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="companyPostalCode"> الرمز البريدي (Postal Code) : </label>
                          <input
                            type="text"
                            name="companyPostalCode"
                            id="companyPostalCode"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.companyPostalCode}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="vendorPostalCode"> الرمز البريدي (Postal Code) : </label>
                          <input
                            type="text"
                            name="vendorPostalCode"
                            id="vendorPostalCode"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.vendorPostalCode}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="companyAdditionalNo"> الرقم الإضافي للعنوان (Additional No) : </label>
                          <input
                            type="text"
                            name="companyAdditionalNo"
                            id="companyAdditionalNo"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.companyAdditionalNo}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="vendorAdditionalNo"> الرقم الإضافي للعنوان (Additional No) : </label>
                          <input
                            type="text"
                            name="vendorAdditionalNo"
                            id="vendorAdditionalNo"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.vendorAdditionalNo}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="companyVATNumber"> رقم تسجيل ضريبة القيمة المضافة (VAT Number) : </label>
                          <input
                            type="text"
                            name="companyVATNumber"
                            id="companyVATNumber"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.companyVATNumber}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />

                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="vendorVATNumber"> رقم تسجيل ضريبة القيمة المضافة (VAT Number) : </label>
                          <input
                            type="text"
                            name="vendorVATNumber"
                            id="vendorVATNumber"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.vendorVATNumber}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />

                        </td>
                      </tr>
                      <tr className="row">
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="otherCompanyID"> معرف آخر (Other Vendor ID) : </label>
                          <input
                            type="text"
                            name="otherCompanyID"
                            id="otherCompanyID"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.otherCompanyID}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />

                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="otherVendorID"> معرف آخر (Other Vendor ID) : </label>
                          <input
                            type="text"
                            name="otherVendorID"
                            id="otherVendorID"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.otherVendorID}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                      <tr className="row">
                        <td>
                          {balanceMore ? (<div className="alert alert-danger">
                            إجمالي الفاتورة أكبر من رصيد الحساب
                            The total bill is greater than the account balance
                          </div>) : null}
                        </td>
                        <td style={{ display: 'flex', justifyContent: "space-between" }}>
                          <label htmlFor="balance"> رصيد الحساب (Account balance) : </label>
                          <input
                            type="text"
                            disabled
                            name="balance"
                            id="balance"
                            placeholder=""
                            autoComplete="off"
                            value={newRec?.balance}
                            onChange={(e) => setNewRec({ ...newRec, [e.target.id]: e.target.value })}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </article>
                <article className="mt-2 mb-2">
                  <TableForm
                    list={list}
                    setList={setList}
                  />
                  <table width="100%" style={{ display: 'grid' }} >
                    <tbody>
                      <tr style={{ display: 'flex', justifyContent: "space-between" }}>
                        <td >SAR. {totalAmounts}</td>
                        <td >الاجمالي ( غير شامل ضريبة القيمة المضافة )</td>
                        <td >Total ( Excluding VAT )</td>
                      </tr>
                      <tr style={{ display: 'flex', justifyContent: "space-between" }}>
                        <td >SAR. {totalDiscounts} </td>
                        <td >مجموع الخصومات</td>
                        <td >Discount</td>
                      </tr>
                      <tr style={{ display: 'flex', justifyContent: "space-between" }}>
                        <td >SAR. {totalTaxable} </td>
                        <td >الاجمالي الخاضع للضريبة ( غير شاملة ضريبة القيمة المضافة )</td>
                        <td >Total Taxable Amount (Excluding VAT)</td>
                      </tr>
                      <tr style={{ display: 'flex', justifyContent: "space-between" }}>
                        <td >SAR. {totalVAT} </td>
                        <td >مجموع ضريبة القيمة المضافة</td>
                        <td >Total VAT</td>
                      </tr>
                      <tr style={{ display: 'flex', justifyContent: "space-between" }}>
                        <td >SAR. {totalAll} </td>
                        <td >اجمالي المبلغ المستحق</td>
                        <td >Total Amount Due</td>
                      </tr>
                    </tbody>
                  </table>
                </article>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <button className="button" onClick={() => setShowInvoice(true)} >
                    عرض الفاتورة Preview Invoice
                  </button>
                  <Link to={`../purchases`} className="btn btn-danger m-1 p-2">
                    أغلاق الصفحة Close Page
                  </Link>
                  <button className="button" onClick={saveRec} >
                    حــفظ الفاتورة Save to Server
                  </button>
                </div>
              </div>
            </>
          )
          }
        </>
      </div>
    </div>
  )
}

export default NewPurchases
