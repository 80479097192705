
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { database } from '../../firebase'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { useAuth } from "../../contexts/AuthContext"


function Projects() {

    const [data, setData] = useState([])

    const [userid, setUserid] = useState('')
    const [name, setName] = useState('')
    const [location, setLocation] = useState('')
    const [accountid, setAccountid] = useState(0)
    const [accountbalance, setAccountbalance] = useState(0)
    const [conditions, setConditions] = useState('')
    const [types, setTypes] = useState('')

    const [contractortype, setContractortype] = useState('')
    const [contractorname, setContractorname] = useState('')
    const [contractormobile, setContractormobile] = useState('')

    const [consultantname, setConsultantname] = useState('')
    const [consultantmobile, setConsultantmobile] = useState('')

    const [users, setUsers] = useState([])
    const [projectsstatus, setProjectsStatus] = useState()
    const [projectstypes, setProjectsTypes] = useState()
    const [contractorstypes, setContractorstypes] = useState('')

    const [showprojectsAll, setshowprojectsAll] = useState(true)
    const [showFormprojects, setshowFormprojects] = useState(false)

    const [idEdit, setIdEdit] = useState(null)
    const [error, setError] = useState("")
    const { currentUser } = useAuth()

    useEffect(() => {
        let projects = null;
        try {
            projects = database.projects.onSnapshot(res => {
                let data = res.docs.map(rec => database.formatDoc(rec))
                setData(data)
            })
            database.projectsstatus.limit(20).get().then(res => {
                let projectsstatus = res.docs.map(rec => database.formatDoc(rec))
                setProjectsStatus(projectsstatus)
            })
            database.projectstypes.limit(20).get().then(res => {
                let projectstypes = res.docs.map(rec => database.formatDoc(rec))
                setProjectsTypes(projectstypes)
            })
            database.contractorstypes.limit(20).get().then(res => {
                let contractorstypes = res.docs.map(rec => database.formatDoc(rec))
                setContractorstypes(contractorstypes)
            })
            database.users.where('roles', '==', 'customers').limit(20).get().then(res => {
                let users = res.docs.map(rec => database.formatDoc(rec))
                setUsers(users)
            })
        } catch {
            setError("Failed to read data ... ")
        } finally {

        }
        return projects
    }, [])

    const userLocation = () => {
        navigator.geolocation.getCurrentPosition((pos) => {
            setLocation(`${pos.coords.latitude},${pos.coords.longitude}`);
        }, (err) => console.warn(`ERROR(${err.code}): ${err.message}`), {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        });
    }

    const openRecord = async () => {
        setIdEdit(null)
        setName('')
        setLocation('')
        setAccountid('')
        setAccountbalance(0)
        setConditions('')
        setTypes('')
        setContractorname('')
        setContractormobile('')
        setContractortype('')
        setConsultantname('')
        setConsultantmobile('')
        setUserid('')

        setshowprojectsAll(false);
        setshowFormprojects(true);
    }

    const saveRecord = async () => {
        if (idEdit) {
            await database.projects.doc(idEdit).update({ name, location, lat: Number(location.slice(0, location.indexOf(','))), lng: Number(location.slice(1, location.indexOf(','))), accountid, accountbalance, conditions, types, contractorname, contractormobile, contractortype, consultantname, consultantmobile, userid, updatedBy: currentUser.uid, updatedAt: database.getCurrentTimestamp() })
            setIdEdit(null)
        } else {
            await database.projects.add({ name, location, lat: Number(location.slice(0, location.indexOf(','))), lng: Number(location.slice(1, location.indexOf(','))), accountid, accountbalance, conditions, types, contractorname, contractormobile, contractortype, consultantname, consultantmobile, userid, createdBy: currentUser.uid, createdAt: database.getCurrentTimestamp() })
        }
        setName('')
        setLocation('')
        setAccountid('')
        setAccountbalance(0)
        setConditions('')
        setTypes('')
        setContractorname('')
        setContractormobile('')
        setContractortype('')
        setConsultantname('')
        setConsultantmobile('')
        setUserid('')

        setshowprojectsAll(true);
        setshowFormprojects(false);
    }

    const editRecord = async (record) => {
        setshowprojectsAll(false);
        setshowFormprojects(true);
        setIdEdit(record.id)

        setName(record.name)
        setLocation(record.location)
        setAccountid(record.accountid)
        setAccountbalance(record.accountbalance)
        setConditions(record.conditions)
        setTypes(record.types)
        setContractorname(record.contractorname)
        setContractormobile(record.contractormobile)
        setContractortype(record.contractortype)
        setConsultantname(record.consultantname)
        setConsultantmobile(record.consultantmobile)
        setUserid(record.userid)

    }

    const closeRecord = async () => {
        setName('')
        setLocation('')
        setAccountid('')
        setAccountbalance(0)
        setConditions('')
        setTypes('')
        setContractorname('')
        setContractormobile('')
        setConsultantname('')
        setConsultantmobile('')
        setContractortype('')
        setUserid('')

        setshowprojectsAll(true);
        setshowFormprojects(false);
    }

    const delRecord = async (id) => {
        var r = window.confirm(`هل أنت متأكد من حذف السجل ${id}`)
        if (r) await database.projects.doc(id).delete()
    }

    return (
        <div>
            <Header />
            <div className="container mt-1 mb-1">
                {error && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>خطأ...</strong> {error}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>}
                <div className="card text-center">
                    <div className="card-header clearfix">
                        <div className="row">
                            <div className="col-sm-1">
                                <button type="button" className="btn btn-primary" onClick={openRecord}>
                                    <i className="bi bi-plus-lg"></i> Add
                                </button>
                            </div>
                            <div className="col-sm-9">
                                <h4> Projects </h4>
                            </div>
                            <div className="col-sm-2">
                                <Link to="/admin" className="btn btn-danger">
                                    Close <i className="bi bi-x-circle" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table">
                            <thead>
                                <tr className="d-flex">
                                    <th className="col-3"> Name </th>
                                    <th className="col-2"> Location </th>
                                    <th className="col-2"> Account id </th>
                                    <th className="col-2"> Balance </th>
                                    <th className="col-3"> Manage </th>
                                </tr>
                            </thead>
                            <tbody>
                                {showprojectsAll && data && data.map(record => (<tr className="d-flex" key={record.id}>
                                    <td className="col-3">{record.name}</td>
                                    <td className="col-2">{record.location}</td>
                                    <td className="col-2">{record.accountid}</td>
                                    <td className="col-2">{record.accountbalance}</td>
                                    <td className="col-3">
                                        <button className="btn btn-primary ms-1" type="button" onClick={() => editRecord(record)}>
                                            <i className="ml-1 bi bi-pencil-square" />
                                            Edit
                                        </button>
                                        <button className="btn btn-danger ms-1" type="button" onClick={() => delRecord(record.id)}>
                                            <i className="ml-1 bi bi-x-circle" />
                                            Delete
                                        </button>
                                    </td>
                                </tr>))
                                }
                            </tbody>
                        </table>
                    </div>
                    {showFormprojects && (<div className="card-footer">

                        <div className="form-group row">
                            <label htmlFor="userid" className="col-sm-2 control-label">User id</label>
                            <div className="col-sm-8">
                                <select placeholder="Select User id" className="form-control" value={userid} onChange={(e) => setUserid(e.target.value)} >
                                    {users && users.length > 0 && users.map(r => (<option key={r.id} value={r.id}> {r.username}
                                    </option>))}
                                </select>
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="name" className="col-sm-2 control-label">Name</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter name" type="text" />
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="location" className="col-sm-2 control-label">Location</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={location} onChange={(e) => setLocation(e.target.value)} placeholder="Enter location" type="text" />
                            </div>
                            <div className="col-sm-2">
                                <button className="btn btn-success" onClick={userLocation}> <i className="bi bi-x-circle" /> User Location </button>
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="accountid" className="col-sm-2 control-label">Account id</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={accountid} onChange={(e) => setAccountid(e.target.value)} placeholder="Enter accountid" type="text" />
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="accountbalance" className="col-sm-2 control-label">Account balance</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={accountbalance} onChange={(e) => setAccountbalance(e.target.value)} placeholder="Enter account balance" type="number" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="conditions" className="col-sm-2 control-label">Projects status</label>
                            <div className="col-sm-8">
                                <select placeholder="Select Projects status" className="form-control" value={conditions} onChange={(e) => setConditions(p => (e.target.value))} >
                                    {projectsstatus && projectsstatus.length > 0 && projectsstatus.map((r, i) => (<option key={i} value={r.description}> {r.description}
                                    </option>))}
                                </select>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="conditions" className="col-sm-2 control-label">Projects types</label>
                            <div className="col-sm-8">
                                <select placeholder="Select Projects types" className="form-control" value={conditions} onChange={(e) => setTypes(p => (e.target.value))} >
                                    {projectstypes && projectstypes.length > 0 && projectstypes.map((r, i) => (<option key={i} value={r.description}> {r.description}
                                    </option>))}
                                </select>
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="contractorname" className="col-sm-2 control-label">Contractor name</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={contractorname} onChange={(e) => setContractorname(e.target.value)} placeholder="Enter contractor name" type="text" />
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="contractormobile" className="col-sm-2 control-label">Contractor mobile</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={contractormobile} onChange={(e) => setContractormobile(e.target.value)} placeholder="Enter contractor mobile" type="text" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="contractortype" className="col-sm-2 control-label">Contractor type</label>
                            <div className="col-sm-8">
                                <select placeholder="Select Contractor type" className="form-control" value={contractortype} onChange={(e) => setContractortype(p => (e.target.value))} >
                                    {contractorstypes && contractorstypes.length > 0 && contractorstypes.map((r, i) => (<option key={i} value={r.description}> {r.description}
                                    </option>))}
                                </select>
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="consultantname" className="col-sm-2 control-label">Consultant name</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={consultantname} onChange={(e) => setConsultantname(e.target.value)} placeholder="Enter consultant name" type="text" />
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="consultantmobile" className="col-sm-2 control-label">Consultant mobile</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={consultantmobile} onChange={(e) => setConsultantmobile(e.target.value)} placeholder="Enter consultant mobile" type="text" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-sm-offset-2 col-sm-10 m-1">
                                <button className="btn btn-primary m-1" onClick={saveRecord}> <i className="bi bi-pencil-square" /> حفظ </button>
                                <button className="btn btn-danger m-1" onClick={closeRecord}> <i className="bi bi-x-circle" /> أغلاق </button>
                            </div>
                        </div>

                    </div>)}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Projects