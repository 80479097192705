import React, { useState, useEffect, useLayoutEffect } from "react"
import { AiOutlineDelete } from "react-icons/ai"
import { v4 as uuidv4 } from "uuid"
import { db } from '../../firebase';
import { collection, getDocs, query, orderBy } from 'firebase/firestore'

const iniListItem = { id: uuidv4(), item: "", unitPrice: 0, description: "", quantity: 1, taxableAmount: 0, discounts: 0, taxRate: 15, taxAmount: 0, itemTotalVAT: 0 };
export default function TableForm({
    list,
    setList,
}) {

    const [listItem, setListItem] = useState(iniListItem)
    const [isEditing, setIsEditing] = useState(false)
    const [inventory, setInventory] = useState()

    const getInventory = () => {
        const productRec = collection(db, 'inventory');
        let productquery = query(productRec, orderBy('createdAt'))
        getDocs(productquery).then(snap => {
            setInventory(snap.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        })
    }

    const productHandeler = (recordid) => {
        var index = inventory.findIndex(x => x.id === recordid);
        setListItem({ id: uuidv4(), item: inventory[index].name, unitPrice: inventory[index].unitPrice, description: inventory[index].description, quantity: 1, taxableAmount: 0, discounts: 0, taxRate: 15, taxAmount: 0, itemTotalVAT: 0 })
    }

    useLayoutEffect(() => {
        getInventory()
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!listItem.item || !listItem.unitPrice || !listItem.description || !listItem.quantity || !listItem.taxRate) {
            alert("Please fill in all inputs")
        } else {

            const newItems = {
                id: uuidv4(),
                item: listItem.item,
                unitPrice: listItem.unitPrice,
                description: listItem.description,
                quantity: listItem.quantity,
                discounts: listItem.discounts,
                taxableAmount: (listItem.quantity * listItem.unitPrice) - listItem.discounts,
                taxRate: listItem.taxRate,
                taxAmount: (((listItem.quantity * listItem.unitPrice) - listItem.discounts) * listItem.taxRate) / 100,
                itemTotalVAT: ((listItem.quantity * listItem.unitPrice) - listItem.discounts) + ((((listItem.quantity * listItem.unitPrice) - listItem.discounts) * listItem.taxRate) / 100),
            }
            setList([...list, newItems])
            setListItem(iniListItem)
            setIsEditing(false)
        }
    }

    const deleteRow = (id) => setList(list.filter((item) => item.id !== id))

    return (
        <>
            <div className="col-sm-6">
                <form onSubmit={handleSubmit}>
                    <div style={{ display: 'grid', }}>
                        <div className="mt-1 mb-1" style={{ display: 'flex', justifyContent: "space-between" }}>
                            <label className="col-sm-5 control-label" htmlFor="inventory">اختر العنصر (Select item) : </label>
                            <div className="col-sm-7">
                                <select placeholder="Select product name" className="form-control" onChange={(e) => productHandeler(e.target.value)} >
                                    {inventory && inventory.length > 0 && inventory.map(r => (<option key={r.id} value={r.id}> {r.name}
                                    </option>))}
                                </select>
                            </div>
                        </div>
                        <div className="mt-1 mb-1" style={{ display: 'flex', justifyContent: "space-between" }}>
                            <label htmlFor="item">العنصر (Item) : </label>
                            <input
                                type="text"
                                name="item"
                                id="item"
                                disabled
                                value={listItem.item}
                                onChange={(e) => setListItem({ ...listItem, [e.target.id]: e.target.value })}
                            />
                        </div>

                        <div className="mt-1 mb-1" style={{ display: 'flex', justifyContent: "space-between" }}>
                            <label htmlFor="unitPrice">سعر الوحدة (Unit Price) : </label>
                            <input
                                type="text"
                                name="unitPrice"
                                id="unitPrice"
                                disabled
                                value={listItem.unitPrice}
                                onChange={(e) => setListItem({ ...listItem, [e.target.id]: Number(e.target.value) })}
                            />
                        </div>

                        <div className="mt-1 mb-1" style={{ display: 'flex', justifyContent: "space-between" }}>
                            <label htmlFor="taxRate">نسبة الضريبة (Tax Rate) : </label>
                            <input
                                type="text"
                                name="taxRate"
                                id="taxRate"
                                disabled
                                value={listItem.taxRate}
                                onChange={(e) => setListItem({ ...listItem, [e.target.id]: Number(e.target.value) })}
                            />
                        </div>
                    </div>

                    <div style={{ display: 'grid' }}>

                        <div className="mt-1 mb-1" style={{ display: 'flex', justifyContent: "space-between" }}>
                            <label htmlFor="item"> الوصف (Description) : </label>
                            <input
                                type="text"
                                name="description"
                                id="description"
                                disabled
                                value={listItem.description}
                                onChange={(e) => setListItem({ ...listItem, [e.target.id]: e.target.value })}
                            />
                        </div>
                        <div className="mt-1 mb-1" style={{ display: 'flex', justifyContent: "space-between" }}>
                            <label htmlFor="quantity">الكمية (Quantity) : </label>
                            <input
                                type="text"
                                name="quantity"
                                id="quantity"
                                placeholder="Quantity"
                                value={listItem.quantity}
                                onChange={(e) => setListItem({ ...listItem, [e.target.id]: Number(e.target.value) })}
                            />
                        </div>

                        <div className="mt-1 mb-1" style={{ display: 'flex', justifyContent: "space-between" }}>
                            <label htmlFor="discounts">الخصم (Discounts) : </label>
                            <input
                                type="text"
                                name="discounts"
                                id="discounts"
                                value={listItem.discounts}
                                onChange={(e) => setListItem({ ...listItem, [e.target.id]: Number(e.target.value) })}
                            />
                        </div>

                    </div>

                    <button className="button mt-1 mb-1" type="submit">
                        {isEditing ? "Editing Row Item" : "Add Table Item"}
                    </button>
                </form>
            </div>
            <table width="100%" >
                <thead>
                    <tr >
                        <td>اسم المنتج</td>
                        <td>سعر الوحدة</td>
                        <td>الوصف</td>
                        <td>الكمية</td>
                        <td>المبلغ الخاضع للضريبة</td>
                        <td>الخصومات</td>
                        <td>نسبة الضريبة</td>
                        <td>مبلغ الضريبة</td>
                        <td>المجموع</td>
                        <td>إدارة</td>
                    </tr>
                    <tr >
                        <td>Item</td>
                        <td>Unit Price</td>
                        <td>Description</td>
                        <td>Quantity</td>
                        <td>Taxable Amount</td>
                        <td>Discounts</td>
                        <td>Tax Rate</td>
                        <td>Tax Amount</td>
                        <td>Item Total VAT</td>
                        <td>Manage</td>
                    </tr>
                </thead>
                <tbody>
                    {list.map(({ id, item, unitPrice, description, quantity, taxableAmount, discounts, taxRate, taxAmount, itemTotalVAT }) => (
                        <tr key={id}>
                            <td>{item}</td>
                            <td>{unitPrice}</td>
                            <td>{description}</td>
                            <td>{quantity}</td>
                            <td>{taxableAmount}</td>
                            <td>{discounts}</td>
                            <td>{taxRate}</td>
                            <td>{taxAmount}</td>
                            <td>{itemTotalVAT}</td>
                            <td>
                                <button onClick={() => deleteRow(id)}>
                                    <AiOutlineDelete />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}
