import React from 'react'
import SocialFlow from "./SocialFlow"
import './Footer.css'

function Footer(props) {
    return (
        <div className="mt-1 mb-1">
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 col-xs-6 pull-Right">
                            <p>من نحن</p>
                            <p className="text-justify">مؤسسة رصانة العمران ... جودة ورصانة في العمران</p>
                            <p className="text-justify">لديها الخبرة والمهارة والموارد لمواجهة تحدي ظروف أعمال المقاولات ولذلك نحن نتوقع احتياجاتهم ونحقق كافة التزاماتنا تجاههم فنحن أهلا للمسئولية أمام المجتمع ونملك تاريخاً من التخطيط وإدارة مواقع عمل آمنة ملتزمين بالعامل الزمني وتحقيق الجودة والتميز في الأداء لذلك فإن مؤسسة رصانة العمران للمقاولات تحافظ على علاقتها بعملائها وتنفيذ المشاريع وفق أفضل معايير الجودة والأداء.</p>
                        </div>
                        <div className="col-sm-2 col-xs-6 pull-Right">
                            <ul>
                                <li>
                                    <a href="/">الرئيسة</a>
                                </li>
                                <li>
                                    <a href="/">المشاريع</a>
                                </li>
                                <li>
                                    <a href="/">مجالات العمل</a>
                                </li>
                                <li>
                                    <a href="/">العملاء</a>
                                </li>
                                <li>
                                    <a href="/">الجودة</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-2 pull-Right shield hidden-xs">
                            <a href="/">rasana.sa</a>
                        </div>
                        <div className="col-sm-2 col-xs-6 pull-Right">
                            <ul>
                                <li>
                                    <a href="/">من نحن</a>
                                </li>
                                <li>
                                    <a href="/">التواصل</a>
                                </li>
                                <li>
                                    <a href="/">المدونة</a>
                                </li>
                                <li>
                                    <a href="/">الأسئلة والاستفسارات</a>
                                </li>
                                <li>
                                    <a href="/">فريق العمل</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-3 col-xs-6 pull-Right">
                            <p> المركز الرئيسي </p>
                            <p> أبها حي المروج </p>
                            <p> 0172258070 </p>
                            <p> 0550077556 </p>
                            <p> www.rasana.sa </p>
                            <a href="mailto: info@rasana.sa">info@rasana.sa</a>
                        </div>
                    </div>
                </div>
            </div>
            <section id="newsletter">
                <div className="container">
                    <div className="row justify-content-center">
                        <p className="text-center">
                            Copyright 2023 Rasana.sa. All right reserved.
                        </p>
                    </div>
                </div>
            </section>
            <section id="newsletter">
                <SocialFlow />
            </section>
        </div>

    )
}

export default Footer
