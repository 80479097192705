
import React, { useState, useEffect } from 'react'
import { database } from '../../firebase'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { useAuth } from "../../contexts/AuthContext"

function ManageUsers() {

    const [data, setData] = useState([])
    const [username, setUsername] = useState(0)
    const [email, setEmail] = useState('')
    const [roles, setRoles] = useState('')
    const [userroles, setUserroles] = useState()

    const [showusersAll, setshowusersAll] = useState(true)
    const [showFormusers, setshowFormusers] = useState(false)

    const [idEdit, setIdEdit] = useState(null)
    const [error, setError] = useState("")
    const { currentUser } = useAuth()
    const [lastDoc, setLastDoc] = useState(null);
    const [noMoreData, setNoMoreata] = useState(false)

    const getUsers = async () => {
        database.users.orderBy('createdAt').startAfter(lastDoc || 0).limit(10).get().then(res => {
            if (res.empty) setNoMoreata(true)
            let data = res.docs.map(rec => database.formatDoc(rec))
            setLastDoc(res.docs[res.docs.length - 1]);
            setData((prevData) => [...prevData, ...data])
        })
    }

    useEffect(() => {
        try {
            getUsers()
            database.userroles.limit(20).get().then(res => {
                let userroles = res.docs.map(rec => database.formatDoc(rec))
                setUserroles(userroles)
            })
        } catch {
            setError("Failed to read data ... ")
        } finally {

        }

    }, [])

    const saveRecord = async () => {
        await database.users.doc(idEdit).update({ roles, updatedBy: currentUser.uid, updatedAt: database.getCurrentTimestamp() })
        setUsername('')
        setEmail('')
        setRoles('')

        setshowusersAll(true);
        setshowFormusers(false);
    }

    const editRecord = async (record) => {

        setshowusersAll(false);
        setshowFormusers(true);
        setIdEdit(record.id)

        setEmail(record.email)
        setUsername(record.username)
        setRoles(record.roles)

    }

    const closeRecord = async () => {
        setUsername('')
        setEmail('')
        setRoles('')

        setshowusersAll(true);
        setshowFormusers(false);
    }

    const delRecord = async (id) => {
        var r = window.confirm(`هل أنت متأكد من حذف السجل ${id}`)
        if (r) await database.users.doc(id).delete()
    }


    return (
        <div>
            <Header />
            <div className="container mt-1 mb-1">
                {error && <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>خطأ...</strong> {error}
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>}
                <div className="card text-center">
                    <div className="card-header clearfix">
                        <div className="row">
                            <div className="col-sm-3">
                                <select placeholder="Choose user roles" className="form-control">
                                    <option value={'admin'}> مدير النظام </option>
                                    <option value={'employees'}>  الموظفين </option>
                                    <option value={'customers'}>  العملاء </option>
                                    <option value={'users'}> المستخدمين </option>
                                </select>
                            </div>
                            <div className="col-sm-6">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="Enter Email Address" aria-label="email-search" aria-describedby="email-search" />
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="email-search">@</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" >
                        <table className="table">
                            <thead>
                                <tr className="d-flex">
                                    <th className="col-3"> اسم المستخدم </th>
                                    <th className="col-3"> البريد الإلكتروني </th>
                                    <th className="col-3"> الدور </th>
                                    <th className="col-3"> إدارة </th>
                                </tr>
                            </thead>
                            <tbody>
                                {showusersAll && data && data.map(record => (<tr className="d-flex" key={record.id}>
                                    <td className="col-3">{record.username}</td>
                                    <td className="col-3">{record.email}</td>
                                    <td className="col-3">{record.roles}</td>
                                    <td className="col-3">
                                        <button className="btn btn-primary ms-1" type="button" onClick={() => editRecord(record)}>
                                            <i className="ml-1 bi bi-pencil-square" />
                                            تعديل
                                        </button>
                                        <button className="btn btn-danger ms-1" type="button" onClick={() => delRecord(record.id)}>
                                            <i className="ml-1 bi bi-x-circle" />
                                            حذف
                                        </button>
                                    </td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                    {showFormusers && (<div className="card-footer">
                        <div className="form-group row m-2">
                            <label htmlFor="name" className="col-sm-2 control-label">اسم المستخدم</label>
                            <div className="col-sm-8">
                                {username}
                            </div>
                        </div>
                        <div className="form-group row m-2">
                            <label htmlFor="email" className="col-sm-2 control-label">البريد الإلكتروني</label>
                            <div className="col-sm-8">
                                {email}
                            </div>
                        </div>

                        <div className="form-group row m-2">
                            <label htmlFor="roles" className="col-sm-2 control-label">الدور</label>
                            <div className="col-sm-8">
                                <select placeholder="Select Roles" className="form-control" value={roles} onChange={(e) => setRoles(e.target.value)} >
                                    {userroles && userroles.length > 0 && userroles.map(r => (<option value={r.description}> {r.description}
                                    </option>))}
                                </select>
                            </div>
                        </div>

                        <div className="form-group row m-2">
                            <div className="col-sm-offset-2 col-sm-10 m-1">
                                <button className="btn btn-primary m-1" onClick={saveRecord}> <i className="bi bi-pencil-square" /> حفظ </button>
                                <button className="btn btn-danger m-1" onClick={closeRecord}> <i className="bi bi-x-circle" /> أغلاق </button>
                            </div>
                        </div>

                    </div>)}
                    <button disabled={noMoreData} className="btn btn-primary m-1" onClick={() => getUsers()}> <i className="bi bi-pencil-square" /> المزيد </button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ManageUsers

