import React, { useContext, useState, useEffect } from "react"
import { auth, database } from "../firebase"


const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  const [user,setUser]=useState(null);

  function signup(email, password, username = null) {
    if (username) {
      return auth.createUserWithEmailAndPassword(email, password).then(user => {
        return database.users.doc(user.user.uid).set({uid:user.user.uid, username:username, email:email, roles:'users', createdBy: user.user.uid, createdAt: database.getCurrentTimestamp()})
      })
    }
    else {
      return auth.createUserWithEmailAndPassword(email, password)
    }
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  const getAdmin = async (user) =>  database.users.doc(user.uid).get().then(doc=>setUser(database.formatDoc(doc)))
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) getAdmin(user)
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [currentUser])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    user
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
