import React from 'react'
import Menu from '../menu/Menu'

function Header(props) {
    return (
        <div>
            <Menu/>
        </div>
    )
}

export default Header

