import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useAuth } from "../../contexts/AuthContext"

export default function AdminRoute({ component: Component, ...rest }) {
  const { currentUser, user } = useAuth()

  return (
    <Route
      {...rest}
      render={props => {
        return (currentUser && user.roles === 'admin') ? <Component {...props} /> : <Redirect to="/signin" />
      }}
    ></Route>
  )
}
