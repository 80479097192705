import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import CenteredContainer from '../centeredcontainer/CenteredContainer'
import Header from '../header/Header'
import Footer from '../footer/Footer'

export default function Signup() {

  const usernameRef = useRef()
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }
    try {
      setError("")
      setLoading(true)
      await signup(emailRef.current.value, passwordRef.current.value, usernameRef.current.value)
      history.push("/")
    } catch {
      setError("Failed to create an account")
    }

    setLoading(false)
  }

  return (
    <div>
      <Header />
      <CenteredContainer>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">مستخدم جديد</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
            <Form.Group id="username">
                <Form.Label>اسم المستخدم </Form.Label>
                <Form.Control type="text" ref={usernameRef} required />
              </Form.Group>
              <Form.Group id="email">
                <Form.Label>البريد الإلكتروني</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>كلمة المرور</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <Form.Group id="password-confirm">
                <Form.Label>تأكيد كلمة المرور</Form.Label>
                <Form.Control type="password" ref={passwordConfirmRef} required />
              </Form.Group>
              <Button disabled={loading} className="w-100 m-1" type="submit">
                تسجيل
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          يوجد لدي حساب بالفعل  <Link to="/signin">تسجيل الدخول</Link>
        </div>
      </CenteredContainer>
      <Footer />
    </div>
  )
}
