import React from 'react'
import carousel001 from '../../assets/images/carousel001.jpg';
import workfields001 from '../../assets/images/workfields001.jpg';
import workfields002 from '../../assets/images/workfields002.jpg';
import workfields003 from '../../assets/images/workfields003.jpg';
import workfields004 from '../../assets/images/workfields004.jpg';
import Header from '../header/Header'
import Footer from '../footer/Footer'

function Home(props) {
    return (
        <div>
            <Header />
            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={carousel001} className="d-block w-100" alt="..." />
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                    <span className="sr-only"></span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true" />
                    <span className="sr-only"></span>
                </a>
            </div>
            <div className="container-fluid mt-5 pt-5">
                <div className="row row-cols-1 row-cols-md-2 g-2">
                    <div className="col">
                        <div className="card h-100">
                            <img src={workfields001} className="card-img-top list-card-image" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">تنفيذ مشاريع الإنشاء</h5>
                                <p className="card-text text-justify">
                                تقوم المؤسسة بتنفيذ المشاريع الإنشائية بجميع مراحلها ابتداءً من الدراسة الفنية والمخططات
                            التصميمية، والتنفيذ والإشراف لكافة أنواع المباني والمنشآت السكنية والخاصة.
                            أن إدارة تنفيذ المشاريع تتعلق بتنظيم وإدارة الموارد البشرية بالطريقة التي يمكن إنجاز المشاريع من خلالها مع مراعاة عوامل الجودة والسلامة والتوقيت والتكلفة.
                                </p>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <img src={workfields002} className="card-img-top list-card-image" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">تنفيذ مشاريع الإنشاء والتسليم على المفتاح</h5>
                                <p className="card-text text-justify">
                                تقوم المؤسسة بتوفير خدمات أعمال التشييد للمباني بشكل كامل ( إنشائي – كهرباء – سباكة- لياسة - تكييف – أنظمة الحريق - التكسيات الخارجية - التطبيقات والتركيب - التشغيل والصيانة) للمرافق بواسطة كادر متميز من المهندسين والفنيين للوصول بالخدمات إلى أعلى درجات الجودة لمجاراة التطور الاقتصادي.
                                </p>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <img src={workfields003} className="card-img-top list-card-image" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">تنفيذ مشاريع عمليات التشغيل والصيانة للمباني</h5>
                                <p className="card-text text-justify">تقوم المؤسسة بتوفير خدمات أعمال الصيانة للمباني ( إنشائي – معماري – كهرباء – سباكة ) لمعالجة أي خلل أو تلف قد ينشأ عن الاستهلاك والاستعمال للمرافق بواسطة كادر متميز من المهندسين والفنيين للوصول بالخدمات إلى أعلى درجات الجودة لمجاراة التطور الاقتصادي.
                                </p>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <img src={workfields004} className="card-img-top list-card-image" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">تنفيذ مشاريع عمليات التأهيل والترميم للمباني</h5>
                                <p className="card-text text-justify">
                                تأهيل وإعادة توظيف المبنى المشيد وفقا لمتطلبات جديدة مع تغيير جزئي أو كلي في المبنى ليتناسب مع وظائف خدمية جديدة مغايرة جزئيا أو كليا لنشاط المبنى وترميم المباني وذلك من خلال عمل جميع احتياجات المبنى والحفاظ على طبيعة ونشاط ذلك المبنى بعد عملية الترميم.
                                </p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default Home

