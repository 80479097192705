import React from 'react'
import './Admin.css'
import { Link } from "react-router-dom"
import Header from '../header/Header'
import Footer from '../footer/Footer'

function Admin() {
    return (
        <div>
            <Header />
            <div className="container mt-1 mb-1">
                <fieldset className="fieldset m-2">
                    <legend> ترميزات النظام </legend>
                    <div className="row">
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/projectsstatus' className="w-100 btn btn-primary btn-lg btn-block">
                                حالة المشاريع
                            </Link>
                        </div>
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/projectstypes' className="w-100 btn btn-primary btn-lg btn-block">
                                أنواع المشاريع
                            </Link>
                        </div>
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/contractorstypes' className="w-100 btn btn-primary btn-lg btn-block">
                                أنواع العقود
                            </Link>
                        </div>
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/paymenttypes' className="w-100 btn btn-primary btn-lg btn-block">
                                أنواع الدفع
                            </Link>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/userroles' className="w-100 btn btn-primary btn-lg btn-block">
                                ادوار المستخدمين
                            </Link>
                        </div>
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/manageusers' className="w-100 btn btn-primary btn-lg btn-block">
                                إدارة المستخدمين
                            </Link>
                        </div>
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/informations' className="w-100 btn btn-primary btn-lg btn-block">
                                بيانات المؤسسة
                            </Link>
                        </div>
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/projects' className="w-100 btn btn-primary btn-lg btn-block">
                                المشاريع
                            </Link>
                        </div>
                    </div>
                </fieldset>


                <fieldset className="fieldset m-2">
                    <legend> بيانات الفواتير </legend>
                    <div className="row">
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/customers' className="w-100 btn btn-primary btn-lg btn-block">
                                بيانات العملاء
                            </Link>
                        </div>
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/receipts' className="w-100 btn btn-primary btn-lg btn-block">
                                وصل العملاء
                            </Link>
                        </div>
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/products' className="w-100 btn btn-primary btn-lg btn-block">
                                المنتجات
                            </Link>
                        </div>
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/sales' className="w-100 btn btn-primary btn-lg btn-block">
                                المبيعات
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/vendors' className="w-100 btn btn-primary btn-lg btn-block">
                                بيانات الموردين
                            </Link>
                        </div>
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/payments' className="w-100 btn btn-primary btn-lg btn-block">
                                وصل الموردين
                            </Link>
                        </div>
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/inventory' className="w-100 btn btn-primary btn-lg btn-block">
                                المواد
                            </Link>
                        </div>
                        <div className="col-sm-3 mt-1 mb-1">
                            <Link to='/purchases' className="w-100 btn btn-primary btn-lg btn-block">
                                المشتريات
                            </Link>
                        </div>
                    </div>
                </fieldset>

            </div>

            <Footer />

        </div>
    )
}
export default Admin