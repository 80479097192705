
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { database } from '../../firebase'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { useAuth } from "../../contexts/AuthContext"

function ProjectsStatus() {

    const [data, setData] = useState([])
    const [description, setDescription] = useState('')

    const [showprojectsstatusAll, setshowprojectsstatusAll] = useState(true)
    const [showFormprojectsstatus, setshowFormprojectsstatus] = useState(false)
    const [idEdit, setIdEdit] = useState(null)
    const [error, setError] = useState("")
    const { currentUser } = useAuth()

    useEffect(() => {
        try {
            return database.projectsstatus.onSnapshot(res => {
                let m = res.docs.map(rec => database.formatDoc(rec))
                setData(m)
            })
        } catch {
            setError("Failed to read data ... ")
        } finally {

        }
    }, [])

    const openRecord = async () => {
        setDescription('')
        setshowprojectsstatusAll(false);
        setshowFormprojectsstatus(true);
    }

    const saveRecord = async () => {
        if (idEdit) {
            await database.projectsstatus.doc(idEdit).update({ description, updatedBy: currentUser.uid, updatedAt: database.getCurrentTimestamp() })
            setIdEdit(null)

        } else {
            await database.projectsstatus.add({ description, createdBy: currentUser.uid, createdAt: database.getCurrentTimestamp() })
        }
        setDescription('')
        setshowprojectsstatusAll(true);
        setshowFormprojectsstatus(false);
    }

    const editRecord = async (record) => {
        setshowprojectsstatusAll(false);
        setshowFormprojectsstatus(true);
        setIdEdit(record.id)
        setDescription(record.description)
    }

    const closeRecord = async () => {
        setDescription('')
        setshowprojectsstatusAll(true);
        setshowFormprojectsstatus(false);
    }

    const delRecord = async (id) => {
        var r = window.confirm(`هل أنت متأكد من حذف السجل ${id}`)
        if (r) await database.projectsstatus.doc(id).delete()
    }

    return (
        <div>
            <Header />
            <div className="container mt-1 mb-1">
                {error && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>خطأ...</strong> {error}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>}
                <div className="card text-center">
                    <div className="card-header clearfix">
                        <div className="row">
                            <div className="col-sm-2">
                                <button type="button" className="btn btn-primary" onClick={openRecord}>
                                    <i className="bi bi-plus-lg"></i> إضافة
                                </button>
                            </div>
                            <div className="col-sm-8">
                                <h4> حالة المشاريع </h4>
                            </div>
                            <div className="col-sm-2">
                                <Link to="/admin" className="btn btn-danger">
                                    أغلاق <i className="bi bi-x-circle" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table">
                            <thead>
                                <tr className="d-flex">
                                    <th className="col-8"> الوصف </th>
                                    <th className="col-4"> إدارة </th>
                                </tr>
                            </thead>
                            <tbody>
                                {showprojectsstatusAll && data && data.map(record => (<tr className="d-flex" key={record.id}>
                                    <td className="col-8">{record.description}</td>
                                    <td className="col-4">
                                        <button className="btn btn-primary" type="button" onClick={() => editRecord(record)}>
                                            <i className="m-1 bi bi-pencil-square" />
                                            تحديث
                                        </button>
                                        <button className="btn btn-danger m-3" type="button" onClick={() => delRecord(record.id)}>
                                            <i className="m-1 bi bi-x-circle" />
                                            حذف
                                        </button>
                                    </td>
                                </tr>))
                                }
                            </tbody>
                        </table>
                    </div>
                    {showFormprojectsstatus && (<div className="card-footer">
                        <div className="form-group row m-1">
                            <label htmlFor="description" className="col-sm-2 control-label">الوصف</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="أدخل الوصف" type="text" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-offset-2 col-sm-10 m-1">
                                <button className="btn btn-primary m-1" onClick={saveRecord}> <i className="bi bi-pencil-square" /> حفظ </button>
                                <button className="btn btn-danger m-1" onClick={closeRecord}> <i className="bi bi-x-circle" /> أغلاق </button>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ProjectsStatus