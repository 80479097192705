
import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { database, db } from '../../firebase'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { useAuth } from "../../contexts/AuthContext"
import dayjs from 'dayjs'
import ReactToPrint from "react-to-print"
import logo from '../../assets/images/logo.jpg';
import './Receipts.css'
import { updateDoc, doc, increment } from 'firebase/firestore'

function Receipts() {

    const [data, setData] = useState([])

    const [receiptDate, setReceiptDate] = useState('')
    const [customer, setCustomer] = useState('')
    const [description, setDescription] = useState('')
    const [totalAmount, setTotalAmount] = useState(0)
    const [paymentType, setPaymentType] = useState('')

    const [customers, setCustomers] = useState('')
    const [paymenttypes, setPaymenttypes] = useState('')

    const [customerid, setCustomerid] = useState("")
    const [showreceiptsAll, setshowreceiptsAll] = useState(true)
    const [showFormreceipts, setshowFormreceipts] = useState(false)
    const [showPrintreceipts, setShowPrintreceipts] = useState(false)
    const [idEdit, setIdEdit] = useState(null)
    const [error, setError] = useState("")
    const { currentUser } = useAuth()
    const componentRef = useRef();

    const customerHandeler = (recordid) => {
        var index = customers.findIndex(x => x.id === recordid);
        setCustomerid(customers[index].id)
        setCustomer(customers[index].name)
    }

    useEffect(() => {
        let receipts = null;
        try {

            receipts = database.receipts.onSnapshot(res => {
                let m = res.docs.map(rec => database.formatDoc(rec))
                setData(m)
            })

            database.customers.limit(200).get().then(res => {
                let customers = res.docs.map(rec => database.formatDoc(rec))
                setCustomers(customers)
            })

            database.paymenttypes.limit(200).get().then(res => {
                let paymenttypes = res.docs.map(rec => database.formatDoc(rec))
                setPaymenttypes(paymenttypes)
            })

        } catch {
            setError("Failed to read data ... ")
        } finally {

        }
        return receipts
    }, [])

    const openRecord = async () => {
        setReceiptDate('')
        setCustomer('')
        setDescription('')
        setTotalAmount(0)
        setPaymentType('')

        setshowreceiptsAll(false);
        setShowPrintreceipts(false);
        setshowFormreceipts(true);
    }

    const saveRecord = async () => {
        if (idEdit) {
            await database.receipts.doc(idEdit).update({ receiptDate, customer, description, totalAmount, paymentType, updatedBy: currentUser.uid, updatedAt: database.getCurrentTimestamp() })
            setIdEdit(null)
            const customerRef = doc(db, "customers", customerid);
            await updateDoc(customerRef, { accountbalance: increment(Number(totalAmount)) });
        } else {
            await database.receipts.add({ receiptDate, customer, description, totalAmount, paymentType, createdBy: currentUser.uid, createdAt: database.getCurrentTimestamp() })
            const customerRef = doc(db, "customers", customerid);
            await updateDoc(customerRef, { accountbalance: increment(Number(totalAmount)) });
        }
        setReceiptDate('')
        setCustomer('')
        setDescription('')
        setTotalAmount(0)
        setPaymentType('')

        setshowreceiptsAll(true);
        setShowPrintreceipts(false);
        setshowFormreceipts(false);
    }

    const editRecord = async (record) => {
        setshowreceiptsAll(false);
        setShowPrintreceipts(false);
        setshowFormreceipts(true);
        setIdEdit(record.id)

        setReceiptDate(record.receiptDate)
        setCustomer(record.customer)
        setDescription(record.description)
        setTotalAmount(record.totalAmount)
        setPaymentType(record.paymentType)

    }

    const closeRecord = async () => {
        setReceiptDate('')
        setCustomer('')
        setDescription('')
        setTotalAmount(0)
        setPaymentType('')

        setshowreceiptsAll(true);
        setshowFormreceipts(false);
        setShowPrintreceipts(false);
    }

    const printRecord = async (record) => {

        setshowreceiptsAll(false);
        setshowFormreceipts(false);
        setShowPrintreceipts(true);

        setReceiptDate(record.receiptDate)
        setCustomer(record.customer)
        setDescription(record.description)
        setTotalAmount(record.totalAmount)
        setPaymentType(record.paymentType)

    }

    const delRecord = async (id) => {
        var r = window.confirm(`هل أنت متأكد من حذف السجل ${id}`)
        if (r) await database.receipts.doc(id).delete()
    }

    return (
        <div>
            <Header />
            <div className="container mt-1 mb-1">
                {error && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>خطأ...</strong> {error}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>}
                <div className="card text-center">
                    <div className="card-header clearfix">
                        <div className="row">
                            <div className="col-sm-2">
                                <button type="button" className="btn btn-primary" onClick={openRecord}>
                                    <i className="bi bi-plus-lg"></i> إضافة
                                </button>
                            </div>
                            <div className="col-sm-8">
                                <h4> وصل العملاء </h4>
                            </div>
                            <div className="col-sm-2">
                                <Link to="/admin" className="btn btn-danger">
                                أغلاق <i className="bi bi-x-circle" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table">
                            <thead>
                                <tr className="d-flex">
                                    <th className="col-2"> تاريخ الوصل</th>
                                    <th className="col-3"> اسم العميل </th>
                                    <th className="col-2"> إجمالي المبلغ </th>
                                    <th className="col-2"> طريقة الدفع </th>
                                    <th className="col-3"> إدارة </th>
                                </tr>
                            </thead>
                            <tbody>
                                {showreceiptsAll && data && data.map(record => (<tr className="d-flex" key={record.id}>
                                    <td className="col-2">{dayjs(record.receiptDate).format('YYYY-MM-DD HH:mm')}</td>
                                    <td className="col-3">{record.customer}</td>
                                    <td className="col-2">{record.totalAmount}</td>
                                    <td className="col-2">{record.paymentType}</td>
                                    <td className="col-3">
                                        <button className="btn btn-secondary m-1" type="button" onClick={() => printRecord(record)}>
                                            <i className="m-1 bi bi-printer" />
                                            طباعة
                                        </button>
                                        <button className="btn btn-danger m-1" type="button" onClick={() => delRecord(record.id)}>
                                            <i className="m-1 bi bi-x-circle" />
                                            حذف
                                        </button>
                                    </td>
                                </tr>))
                                }
                            </tbody>
                        </table>
                    </div>

                    {showFormreceipts && (<div className="card-footer">

                        <div className="form-group row m-1">
                            <label htmlFor="receiptDate" className="col-sm-2 control-label">تاريخ الوصل</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={receiptDate} onChange={(e) => setReceiptDate(e.target.value)} placeholder="ادخل تاريخ الوصل" type="datetime-local" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="customer" className="col-sm-2 control-label"> اسم العميل </label>
                            <div className="col-sm-8">
                                <select placeholder="Select customer" className="form-control" onChange={(e) => customerHandeler(e.target.value)} >
                                    {customers && customers.length > 0 && customers.map(r => (<option key={r.id} value={r.id}> {r.name}
                                    </option>))}
                                </select>
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="description" className="col-sm-2 control-label">الوصف</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="أدخل الوصف" type="text" />
                            </div>
                        </div>

                        <div className="form-group row m-1">
                            <label htmlFor="totalAmount" className="col-sm-2 control-label">اجمالي المبلغ</label>
                            <div className="col-sm-8">
                                <input className="form-control" value={totalAmount} onChange={(e) => setTotalAmount(e.target.value)} placeholder="ادخل اجمالي المبلغ" type="number" />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="paymentType" className="col-sm-2 control-label">طريقة الدفع</label>
                            <div className="col-sm-8">
                                <select placeholder="Select payment type" className="form-control" value={paymentType} onChange={(e) => setPaymentType(p => (e.target.value))} >
                                    {paymenttypes && paymenttypes.length > 0 && paymenttypes.map((r, i) => (<option key={i} value={r.description}> {r.description}
                                    </option>))}
                                </select>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-sm-offset-2 col-sm-10 m-1">
                                <button className="btn btn-primary m-1" onClick={saveRecord}> <i className="bi bi-pencil-square" /> حفظ </button>
                                <button className="btn btn-danger m-1" onClick={closeRecord}> <i className="bi bi-x-circle" /> أغلاق </button>
                            </div>
                        </div>
                    </div>)}


                    {showPrintreceipts && (<div>
                        <div ref={componentRef} >
                            <div className="container mt-1 mb-1">
                                <div className="card text-center">
                                    <div className="row mt-1 mb-1">
                                        <div className="center-image">
                                            <img src={logo} alt="acetaamir" />
                                        </div>
                                    </div>
                                    <div className="row m-3 p-3">
                                        <div className="col-sm-12">
                                            تاريخ الوصل :   {dayjs(receiptDate).format('YYYY-MM-DD HH:mm')}
                                        </div>
                                    </div>
                                    <div className="row m-3 p-3">
                                        <div className="col-sm-12">
                                            طريقة الدفع : {paymentType}
                                        </div>
                                    </div>
                                    <div className="row m-3 p-3">
                                        <div className="col-sm-12">
                                            الاسم : {customer}
                                        </div>
                                    </div>
                                    <div className="row m-3 p-3">
                                        <div className="col-sm-12">
                                            الوصف : {description}
                                        </div>
                                    </div>
                                    <div className="row m-3 p-3">
                                        <div className="col-sm-12">
                                            اجمالي المبلغ : {totalAmount}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-sm-offset-2 col-sm-10 m-1">
                                <ReactToPrint copyStyles={true} trigger={() => (<button className="btn btn-primary m-1 p-2"> طباعة / تحميل </button>)} content={() => componentRef.current} />
                                <button className="btn btn-danger m-1" onClick={closeRecord}> <i className="bi bi-x-circle" /> أغلاق </button>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Receipts
