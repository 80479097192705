import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { AuthProvider } from "../contexts/AuthContext"
import PrivateRoute from "./privateroute/PrivateRoute"
import AdminRoute from "./adminroute/AdminRoute"

import Home from './home/Home'
import Signup from "./signup/Signup"
import Profile from "./profile/Profile"
import Signin from "./signin/Signin"
import ForgotPassword from "./forgotpassword/ForgotPassword"

import ManageUsers from "./manageusers/ManageUsers"

import ProjectsStatus from "./projectsstatus/ProjectsStatus"
import ProjectsTypes from "./projectstypes/ProjectsTypes"
import ContractorsTypes from "./contractorstypes/ContractorsTypes"
import UserRoles from "./userroles/UserRoles"

import Informations from "./informations/Informations"
import PaymentTypes from "./paymenttypes/PaymentTypes"

import Customers from "./customers/Customers"
import Vendors from "./vendors/Vendors"
import Projects from "./projects/Projects"

import UpdateProfile from "./updateprofile/UpdateProfile"
import Admin from "./admin/Admin"

import Sales from "./sales/Sales"
import NewSales from "./sales/NewSales"
import Products from "./products/Products"

import Purchases from "./purchases/Purchases"
import NewPurchases from "./purchases/NewPurchases"
import Inventory from "./inventory/Inventory"

import Receipts from "./receipts/Receipts"
import Payments from "./payments/Payments"

function App() {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <Route path="/" exact component = {Home}/>
          <PrivateRoute path="/profile" component = {Profile} />
          <PrivateRoute path="/updateprofile" component = {UpdateProfile} />
          <AdminRoute path="/admin" component = {Admin} />
          <Route path="/signup" component = {Signup} />
          <Route path="/signin" component = {Signin} />
          <Route path="/forgotpassword" component = {ForgotPassword} />

          <Route path="/manageusers" component = {ManageUsers} />
          <Route path="/projectsstatus" component = {ProjectsStatus} />
          <Route path="/projectstypes" component = {ProjectsTypes} />
          <Route path="/contractorstypes" component = {ContractorsTypes} />
          <Route path="/userroles" component = {UserRoles} />
          <Route path="/informations" component = {Informations} />
          <Route path="/paymenttypes" component = {PaymentTypes} />

          <Route path="/customers" component = {Customers} />
          <Route path="/vendors" component = {Vendors} />
          <Route path="/projects" component = {Projects} />
          
          <Route path="/sales" component = {Sales} />
          <Route path="/newsales" exact component = {NewSales} />
          <Route path="/newsales/:id" component = {NewSales} />
          <Route path="/products" component = {Products} />

          <Route path="/purchases" component = {Purchases} />
          <Route path="/newpurchases" exact component = {NewPurchases} />
          <Route path="/newpurchases/:id" component = {NewPurchases} />
          <Route path="/inventory" component = {Inventory} />

          <Route path="/receipts" component = {Receipts} />
          <Route path="/payments" component = {Payments} />

        </Switch>
      </AuthProvider>
    </Router>
  )
}
export default App